<template>
  <div>
    <div class="header">
      <div class="header-left">
        <a href="javascript:void(0)" class="burger-menu" @click="addToggleClass()">
          <i data-feather="menu" class="fa fa-bars" style="font-size: 20px;"></i>
        </a>
        <h2 class="content-title pt-3 pl-0">{{ title }}</h2>
      </div>


      <div class="header-right">
        <div class="row">
          <div class="col-lg-12" style="width: 300px;" v-if="!hasFullAccess">
            <Field v-slot="{ field }" name="branch_id" v-model="branch_id">
              <Multiselect v-bind="field" ref="multiselect" mode="single" trackBy="title" label="title" valueProp="value"
                placeholder="Select Branch" class="form-control" v-model="branch_id" :options="branches"
                @select="updateBranch" searchable="true" />
            </Field>
          </div>
        </div>

        <!-- <div class="user-menu">
          <img src="/static/img/notification.png" alt="" />
          <a>{{ user.name }}</a>
        </div> -->
        <div class="dropdown dropdown-loggeduser">
          <a href class="dropdown-link" data-toggle="dropdown">
            <div class="avatar avatar-sm">
              <img :src="user.profile_image
                ? user.profile_image
                : '/static/img/profile-placeholder.jpg'
                " class="rounded-circle" />
            </div>
            <i class="fa fa-angle-down"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <div class="dropdown-menu-body">
              <router-link to="/profile">
                <a href class="dropdown-item">
                  <img src="static/img/profile-icon.svg" alt="" class="mr-2" /> My Profile
                </a>
              </router-link>
              <a href class="dropdown-item" @click="logout()">
                <img src="static/img/logout-icon.svg" class="mr-2" alt="" /> Sign Out
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css"
import { Field, } from "vee-validate";
export default {
  name: "HeaderTop",

  components: {
    Multiselect, Field,
  },

  data() {
    return {
      user: {
        name: "",
        email: "",
        profile_image: "",
      },
      organizations: [],
      organization: {},
      branches: [],
      branch_id: localStorage.getItem("branch_id")
    };
  },

  computed: {
    title() {
      return this.$storeService.getters.getTitle;
    },
    hasFullAccess() {
      return this.$storeService.getters.getHasFullAccess;
    }
  },

  mounted() {
    this.branch_id = localStorage.getItem("branch_id");
    this.getMe();
    // EventBus.$on("profile_image", (payload) => {
    //this.getMe();
    //});
    this.emitter.on("profile_image", isOpen => {
      console.log(isOpen);
      this.getMe();
    });
    this.emitter.on("update-branch", isOpen => {
      console.log(isOpen);
      this.getMe();
    });
  },
  methods: {
    addToggleClass() {
      window.$('body').toggleClass('toggle-sidebar');
    },
    updateBranch() {
      localStorage.setItem('branch_id', this.branch_id);
      location.reload();
    },
    getMe() {
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "me",
        })
        .then((res) => {
          this.user = res.info;
          this.user_type = res.info.user_type;
          // This is for checking user is supper asdmin
          this.$storeService.commit("setUserType", res.info.user_type);
          if (res.info.user_type) {
            let isAdminUser = false;
            if (res.info.user_type == "SUPER_ADMIN" || res.info.user_type == "PARTNER" || res.info.user_type == "BRANCH_MANAGER"){
              isAdminUser = true;
            }
            this.$storeService.commit("hasFullAccess", isAdminUser);
          }
          // This is for checking branches
          if (res.info.branches) {
            this.$storeService.commit("setBranches", res.info.branches);
            var allBranches = [...res.info.branches];
            var allBranchObj = {
              "name": "All Branch",
              "branch_id": ""
            };
            var flag = false;
            allBranches.forEach((obj) => {
              if (obj.name !== "All Branch") {
                flag = true;
              }
            });
            if (flag) {
              allBranches.unshift(allBranchObj);
            }
            this.$storeService.commit("setAllBranches", allBranches);
          }
          this.branches = [];
          for (var i = 0; i < res.info.branches.length; i++) {
            var obj = {
              "title": res.info.branches[i].name,
              "value": res.info.branches[i].uuid
            }
            this.branches.push(obj);
          }
          if (!this.branch_id) {
            localStorage.setItem('branch_id', this.branch_id);
          }
          if (res.info.user_type == "SUPER_ADMIN" || res.info.user_type == "PARTNER" || res.info.user_type == 'BRANCH_MANAGER') {
            if (res.info.branches) {
              var officeBranches = [...res.info.branches];
              var officeObj = {
                "name": "Corporate",
                "branch_id": ""
              };
              var officeExpense = false;
              officeBranches.forEach((obj) => {
                if (obj.name !== "Corporate") {
                  officeExpense = true;
                }
              });
              if (officeExpense) {
                officeBranches.unshift(officeObj);
              }
              this.$storeService.commit("setOfficeExpensesBranch", officeBranches);
            }

          }

          // setDashBoardBranches set Dashboard branch
          if (res.info.user_type == "SUPER_ADMIN" || res.info.user_type == "PARTNER") {
            if (res.info.branches) {
              var dashBoardBranch = [...res.info.branches];
              var dashboardObj = {
                "name": "All Branch",
                "branch_id": ""
              };
              var dashboard = false;
              dashBoardBranch.forEach((obj) => {
                if (obj.name !== "All Branch") {
                  dashboard = true;
                }
              });
              if (dashboard) {
                dashBoardBranch.unshift(dashboardObj);
              }
              this.$storeService.commit("setDashBoardBranches", dashBoardBranch);
            }

          }else{
              let dashBoardBranch = [...res.info.branches];
              this.$storeService.commit("setDashBoardBranches", dashBoardBranch);
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, {
            position: 'top-right'
          });
        });
    },
    logout() {
      this.$api.logout();
    },
  }
};
</script>


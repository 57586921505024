<template>
  <div>
    <div class="content-body">
      <div class="component-section no-code">
        <div class="container-fluid px-0">
          <div class="row">
            <div class="col-lg-12">
              <h5 @click="$router.go(-1)" style="cursor: pointer">
                <i class="fas fa-angle-left mr-2"></i><span>Renew Loan Application</span>
              </h5>
            </div>
            <div class="col-lg-12 text-right"></div>
            <div class="col-lg-12">
              <ul id="myTab" role="tablist" class="nav nav-tabs worker_tabs">
                <li class="nav-item">
                  <a
                    id="tab-1"
                    data-toggle="tab"
                    href="#tab_1"
                    role="tab"
                    aria-controls="tab_1"
                    aria-selected="true"
                    class="nav-link active"
                    @click="getLOanApplicationInfo()"
                    >Applicant Details</a
                  >
                </li>
                <!-- <li class="nav-item">
                  <a
                    id="tab-2"
                    data-toggle="tab"
                    href="#tab_2"
                    role="tab"
                    aria-controls="tab_2"
                    @click="getLOanApplicationInfo()"
                    aria-selected="false"
                    class="nav-link"
                    >Guarantor Details</a
                  >
                </li> -->
              </ul>
              <div id="myTabContent" class="tab-content">
                <div
                  id="tab_1"
                  role="tabpanel"
                  aria-labelledby="tab-1"
                  class="tab-pane fade active show"
                >
                  <div
                    class="col-lg-12"
                    v-if="
                      application_status == 'SETTLEMENT_INITIATED' ||
                      application_status == 'SETTLEMENT_APPROVED' ||
                      application_status == 'LOAN_CLOSED'
                    "
                  >
                    <div
                      style="border-radius: 6px !important"
                      class="alert alert-danger alert-dismissible fade show"
                      role="alert"
                    >
                      <p
                        style="margin: 0px !important"
                        v-if="application_status == 'SETTLEMENT_INITIATED'"
                      >
                        This application has been requested for settlement.
                      </p>
                      <p
                        style="margin: 0px !important"
                        v-if="application_status == 'SETTLEMENT_APPROVED'"
                      >
                        Settlement has been approved for this application.
                      </p>
                      <p
                        style="margin: 0px !important"
                        v-if="application_status == 'LOAN_CLOSED'"
                      >
                        This loan application has been closed.
                      </p>
                      <button
                        style="top: 6px !important"
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="card rounded-2" style="border-radius: 10px">
                      <div
                        style="border-bottom: 1px solid #ccc"
                        class="card-header pb-2 pt-3"
                      >
                        <h4 class="text-bolder">Basic Details</h4>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="row">
                              <div class="col-lg-8">
                                <div class="row">
                                  <div class="col-lg-4">
                                    <h5 class="label-text">Application Id</h5>
                                  </div>
                                  <div class="col-lg-8">
                                    <h5 class="text-bolder">
                                      {{ loanApplication.application_id }}
                                    </h5>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-lg-8"
                                v-if="loanApplication?.parent_loan != null"
                              >
                                <div class="row">
                                  <div class="col-lg-4">
                                    <h5 class="label-text">Old Application Id</h5>
                                  </div>
                                  <div class="col-lg-8">
                                    <h5 class="text-bolder">
                                      {{ loanApplication?.parent_loan?.application_id }}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-8">
                                <div class="row">
                                  <div class="col-lg-4">
                                    <h5 class="label-text">CIN Number</h5>
                                  </div>
                                  <div class="col-lg-8">
                                    <h5 class="text-bolder">
                                      <a
                                         style="text-decoration:underline; color:#005da9"
                                        id="customer-btn"
                                        target="_blank"
                                        :href="
                                          '/customer-detail/' +
                                          loanApplication?.user?.uuid
                                        "
                                        >{{ loanApplication.user?.cin_number }}</a
                                      >
                                    </h5>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-8">
                                <div class="row">
                                  <div class="col-lg-4">
                                    <h5 class="label-text">Applicant Name</h5>
                                  </div>
                                  <div class="col-lg-8">
                                    <h5 class="text-bolder">
                                      {{ loanApplication.applicant_name }}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-8">
                                <div class="row">
                                  <div class="col-lg-4">
                                    <h5 class="label-text">Mobile Number</h5>
                                  </div>
                                  <div class="col-lg-8">
                                    <h5 class="text-bolder">
                                      {{ loanApplication.mobile_number }}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-8">
                                <div class="row">
                                  <div class="col-lg-4">
                                    <h5 class="label-text">Permanent Address</h5>
                                  </div>
                                  <div class="col-lg-8">
                                    <h5 class="text-bolder">
                                      {{ loanApplication?.home_address?.address }}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-8">
                                <div class="row">
                                  <div class="col-lg-4">
                                    <h5 class="label-text">Office Address</h5>
                                  </div>
                                  <div class="col-lg-8">
                                    <h5 class="text-bolder">
                                      {{ loanApplication?.office_address?.address }}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-2" style="cursor: pointer">
                            <div class="">
                              <h5 class="text-bolder">Photo</h5>
                              <img
                                :src="loanApplication.applicant_photo"
                                class="img-fluid"
                                width="120"
                                v-if="loanApplication.applicant_photo"
                                v-on:click="openImage(loanApplication.applicant_photo, 0)"
                              />
                            </div>
                          </div>
                          <div class="col-lg-2" style="cursor: pointer">
                            <div class="">
                              <h5 class="text-bolder">Signature</h5>
                              <img
                                :src="loanApplication.applicant_thumb_img"
                                class="img-fluid"
                                width="120"
                                v-if="loanApplication.applicant_thumb_img"
                                v-on:click="
                                  openImage(loanApplication.applicant_thumb_img, 0)
                                "
                              />
                            </div>
                          </div>
                          <div
                            class="col-lg-2"
                            style="cursor: pointer"
                            v-if="loanApplication.applicant_video != null"
                          >
                            <div class="">
                              <h5 class="text-bolder">Video</h5>
                              <video
                                v-if="loanApplication.applicant_video"
                                controls
                                id="video-tag"
                                style="
                                  width: 80% !important;
                                  height: 153px;
                                  border-radius: 10px;
                                  object-fit: contain;
                                "
                              >
                                <source
                                  style="
                                    width: 80% !important;
                                    height: 153px;
                                    border-radius: 10px;
                                    object-fit: contain;
                                  "
                                  id="video-source"
                                  :src="loanApplication.applicant_video"
                                />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Loan Details -->
                  <div class="col-lg-12 pt-3">
                    <div class="card rounded-2" style="border-radius: 10px">
                      <div
                        style="border-bottom: 1px solid #ccc"
                        class="card-header pb-2 pt-3"
                      >
                        <div class="d-flex justify-content-between">
                          <h4 class="text-bolder">Loan Requirement</h4>
                          <a
                            href="javascript:void(0)"
                            v-if="
                              loanApplication?.paid_count == 0 &&
                              hasFullAccess &&
                              access.can_update == 1
                            "
                            style="cursor: pointer !important"
                            class="text-bolder"
                            @click="onUpdateDate()"
                            >Edit</a
                          >
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="row">
                              <div class="col-lg-6">
                                <h5 class="label-text">Loan Type :</h5>
                              </div>
                              <div class="col-lg-6">
                                <h5 class="text-bolder mx-3">
                                  {{ loanApplication?.loan_type?.title }}

                                  {{
                                    " (" +
                                    $helperService.getTitleCase(
                                      loanApplication?.loan_type?.type
                                    ) +
                                    ")"
                                  }}
                                  -
                                  {{
                                    $helperService.getTitleCase(
                                      loanApplication?.loan_type?.level
                                    )
                                  }}
                                </h5>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-lg-6">
                                <h5 class="label-text">Apply Date :</h5>
                              </div>
                              <div class="col-lg-6">
                                <h5 class="text-bolder mx-3">
                                  {{
                                    $helperService.getFormattedDate(
                                      loanApplication.created_at
                                    )
                                  }}
                                </h5>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-6">
                                <h5 class="label-text">Cycle Date :</h5>
                              </div>
                              <div class="col-lg-6">
                                <h5 class="text-bolder mx-3">
                                  {{
                                    $helperService.getFormattedDateOnly(
                                      loanApplication.cycle_start_date
                                    )
                                  }}
                                </h5>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-lg-6">
                                <h5 class="label-text">Disbursement Date :</h5>
                              </div>
                              <div class="col-lg-6">
                                <h5 class="text-bolder mx-3">
                                  {{
                                    $helperService.getFormattedDateOnly(
                                      loanApplication.disbursed_at
                                    )
                                  }}
                                </h5>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-lg-6">
                                <h5 class="label-text">Disbursement By :</h5>
                              </div>
                              <div class="col-lg-6">
                                <h5 class="text-bolder mx-3">
                                  {{ loanApplication?.disbursed_by?.name }}
                                </h5>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="row">
                              <div class="col-lg-6">
                                <h5 class="label-text">Requested Loan Amount :</h5>
                              </div>
                              <div class="col-lg-6">
                                <h5 class="text-bolder mx-3">
                                  {{
                                    $helperService.getFormattedCurrency(
                                      loanApplication.loan_amount
                                    )
                                  }}
                                </h5>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-6">
                                <h5 class="label-text">Max Loan Approved Amount :</h5>
                              </div>
                              <div class="col-lg-6">
                                <h5 class="text-bolder mx-3">
                                  {{
                                    $helperService.getFormattedCurrency(
                                      loanApplication.max_approved_amount
                                        ? loanApplication.max_approved_amount
                                        : 0
                                    )
                                  }}
                                </h5>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-lg-6">
                                <h5 class="label-text">Sanction Amount :</h5>
                              </div>
                              <div class="col-lg-6">
                                <h5 class="text-bolder mx-3">
                                  {{
                                    $helperService.getFormattedCurrency(sanctionAmount)
                                  }}
                                </h5>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-lg-6">
                                <h5 class="label-text">
                                  Processing Fee({{
                                    loanApplication?.processing_fee_percent
                                  }}%) :
                                </h5>
                              </div>
                              <div class="col-lg-6">
                                <h5 class="text-bolder mx-3">
                                  {{
                                    $helperService.getFormattedCurrency(
                                      loanApplication?.processing_fee
                                    )
                                  }}
                                </h5>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-lg-6">
                                <h5 class="label-text">
                                  Processing Fee GST({{ loanApplication?.gst_percent }}%)
                                  :
                                </h5>
                              </div>
                              <div class="col-lg-6">
                                <h5 class="text-bolder mx-3">
                                  {{
                                    $helperService.getFormattedCurrency(
                                      loanApplication?.gst
                                    )
                                  }}
                                </h5>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-6">
                                <h5 class="label-text">Disbursed Loan Amount :</h5>
                              </div>
                              <div class="col-lg-6">
                                <h5 class="text-bolder mx-3">
                                  {{
                                    $helperService.getFormattedCurrency(
                                      loanApplication.disbursed_loan_amount
                                    )
                                  }}
                                </h5>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-6">
                                <h5 class="label-text">
                                  Disbursed Loan Amount After Round Off :
                                </h5>
                              </div>
                              <div class="col-lg-6">
                                <h5 class="text-bolder mx-3">
                                  {{
                                    $helperService.getFormattedCurrency(
                                      loanApplication?.disbursed_loan_amount_round_off
                                    )
                                  }}
                                </h5>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-6">
                                <h5 class="label-text">Total Loan Amount :</h5>
                              </div>
                              <div class="col-lg-6">
                                <h5 class="text-bolder mx-3">
                                  {{
                                    $helperService.getFormattedCurrency(
                                      loanApplication.total_loan_amount
                                    )
                                  }}
                                </h5>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-6">
                                <h5 class="label-text">Paid Installments :</h5>
                              </div>
                              <div class="col-lg-6">
                                <h5 class="text-bolder mx-3">
                                  {{ loanApplication.paid_installments }}
                                </h5>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-6">
                                <h5 class="label-text">Paid Amount :</h5>
                              </div>
                              <div class="col-lg-6">
                                <h5 class="text-bolder mx-3">
                                  {{
                                    $helperService.getFormattedCurrency(
                                      loanApplication.paid_amount
                                    )
                                  }}
                                </h5>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-6">
                                <h5 class="label-text">Due Installments :</h5>
                              </div>
                              <div class="col-lg-6">
                                <h5 class="text-bolder mx-3">
                                  {{ loanApplication.due_installments }}
                                </h5>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-6">
                                <h5 class="label-text">Due Amount :</h5>
                              </div>
                              <div class="col-lg-6">
                                <h5 class="text-bolder mx-3">
                                  {{
                                    $helperService.getFormattedCurrency(
                                      loanApplication.due_amount
                                    )
                                  }}
                                </h5>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-lg-6">
                                <h5 class="label-text">
                                  Total Due Amount With Penalty :
                                </h5>
                              </div>
                              <div class="col-lg-6">
                                <h5 class="text-bolder mx-3">
                                  {{
                                    $helperService.getFormattedCurrency(
                                      loanApplication.total_due_amount
                                    )
                                  }}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Renew Form -->
                  <div class="col-lg-12 pt-3">
                    <div class="card rounded-2" style="border-radius: 10px">
                      <div
                        style="border-bottom: 1px solid #ccc"
                        class="card-header pb-2 pt-3"
                      >
                        <div class="row">
                          <div class="col-lg-6">
                            <h4 class="text-bolder">Renew Loan Form</h4>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <Form @submit="save">
                          <div class="row pt-3">
                            <div :class="'col-lg-3 col-sm-3'">
                              <label for="loan_amount"
                                >Loan Amount <span class="text-danger">*</span></label
                              >
                              <Field
                                name="loan_amount"
                                id="loan_amount"
                                class="form-control"
                                rules="required:loan amount|numeric|multiply"
                                :validateOnInput="true"
                                v-model="renew_loan.loan_amount"
                                type="text"
                                :placeholder="'Enter Amount'"
                                autocapitalize="false"
                              />
                              <ErrorMessage name="loan_amount" class="validation-msg" />
                            </div>
                            <div :class="'col-lg-3 col-sm-3'">
                              <label for="loan_amount"
                                >Loan Type <span class="text-danger">*</span></label
                              >
                              <Field
                                v-slot="{feild}"
                                name="loan_type"
                                rules="required:loan type,true"
                                :validateOnInput="true"
                                v-model="renew_loan.loan_type_id"
                              >
                                <Multiselect
                                  v-bind="feild"
                                  ref="loan_type"
                                  mode="single"
                                  trackBy="title"
                                  label="title"
                                  valueProp="value"
                                  placeholder="Select Loan Type"
                                  class="form-control"
                                  v-model="renew_loan.loan_type_id"
                                  :options="loanTypeList"
                                />
                              </Field>
                              <ErrorMessage name="loan_type" class="validation-msg" />
                            </div>
                            <div :class="'col-lg-3 col-sm-3'">
                              <label for="notes">Note </label>
                              <Field
                                name="notes"
                                id="notes"
                                class="form-control"
                                :validateOnInput="true"
                                v-model="renew_loan.description"
                                type="text"
                                :placeholder="'Enter notes'"
                                autocapitalize="false"
                              />
                              <ErrorMessage name="notes" class="validation-msg" />
                            </div>

                            <div :class="'col-lg-2 col-sm-2 pt-4'">
                              <button
                                type="submit"
                                class="btn btn-brand-01 wb-save-btn"
                                id="loan-application-btn"
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="tab_2"
                  role="tabpanel"
                  aria-labelledby="tab-2"
                  class="tab-pane fade"
                >
                  <div class="row">
                    <div class="col-lg-12 px-5">
                      <div class="card rounded-5">
                        <div class="card-body p-3 p-lg-3">
                          <div class="row">
                            <div
                              class="col-12 text-right"
                              v-if="application_status == 'DOCUMENT_VERIFIED'"
                            >
                              <a @click="addGuarantor()" class="btn btn-brand-02"
                                >+ Add
                              </a>
                            </div>
                          </div>
                          <hr />
                          <div class="table-responsive" style="min-height: auto">
                            <table class="table table-primary mg-b-0">
                              <thead>
                                <tr>
                                  <th>Guarantor Name</th>
                                  <th>Guarantor No.</th>
                                  <th>Photo</th>
                                  <th>Signature</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody v-if="guarantorList.length > 0">
                                <tr
                                  v-for="guarantor in guarantorList"
                                  :key="guarantor.id"
                                >
                                  <td>{{ guarantor.guarantor_name }}</td>
                                  <td>
                                    +91 {{ guarantor.mobile_number }}

                                    {{
                                      guarantor.alt_mobile_number_1
                                        ? ", " + guarantor.alt_mobile_number_1
                                        : ""
                                    }}
                                    {{
                                      guarantor.alt_mobile_number_2
                                        ? ", " + guarantor.alt_mobile_number_2
                                        : ""
                                    }}
                                  </td>
                                  <td>
                                    <img
                                      :src="guarantor.guarantor_photo"
                                      class="img-fluid"
                                      width="80"
                                      height="80"
                                      v-if="guarantor.guarantor_photo"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      :src="guarantor.guarantor_thumb_img"
                                      class="img-fluid"
                                      width="50"
                                      height="50"
                                      v-if="guarantor.guarantor_thumb_img"
                                    />
                                  </td>
                                  <td>
                                    <a
                                      title="view"
                                      class="mx-2"
                                      style="cursor: pointer"
                                      @click="editGuarantor(guarantor)"
                                      ><img src="/static/img/eye-icon.svg" alt=""
                                    /></a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <template v-if="guarantorList.length == 0">
                              <div class="text-center m-5">
                                <h3>Sorry,record not found.</h3>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="tab_3"
                  role="tabpanel"
                  aria-labelledby="tab-3"
                  class="tab-pane fade"
                >
                  <div class="worker_serch_warp">
                    <div class="row">
                      <div class="col-lg-4 d-flex flex-row">
                        <h5 class="label-text">Requested Loan Amount :</h5>
                        <h5 class="text-bolder mx-3">
                          {{
                            $helperService.getFormattedCurrency(
                              loanApplication.loan_amount
                            )
                          }}
                        </h5>
                      </div>
                      <div class="col-lg-4 d-flex flex-row">
                        <h5 class="label-text">Disbursed Loan Amount :</h5>
                        <h5 class="text-bolder mx-3">
                          {{
                            $helperService.getFormattedCurrency(
                              loanApplication.disbursed_loan_amount_round_off
                            )
                          }}
                        </h5>
                      </div>

                      <div class="col-lg-4 d-flex flex-row"></div>
                      <div class="col-lg-4 d-flex flex-row">
                        <h5 class="label-text">Total Due Amount With Penalty :</h5>
                        <h5 class="text-bolder mx-3">
                          {{
                            $helperService.getFormattedCurrency(
                              loanApplication.total_due_amount
                            )
                          }}
                        </h5>
                      </div>
                      <div class="col-lg-4 d-flex flex-row">
                        <h5 class="label-text">Total Loan Amount :</h5>
                        <h5 class="text-bolder mx-3">
                          {{
                            $helperService.getFormattedCurrency(
                              loanApplication.total_loan_amount
                            )
                          }}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="card rounded-5">
                    <div class="card-body p-3 p-lg-3">
                      <div class="row">
                        <div class="col-lg-3 mb-2">
                          <VueDatePicker
                            placeholder="Select Date Range"
                            :format="'dd/MM/yyyy'"
                            style="height: 46px !important"
                            v-model="date"
                            range
                            :type="boolean"
                            :default="true"
                            :enable-time-picker="false"
                          >
                          </VueDatePicker>
                        </div>
                        <div class="col-lg-3 mb-2">
                          <a
                            @click="getList(1, 'STATEMENT')"
                            class="btn btn-brand-01 mr-2"
                            >Search
                          </a>
                          <a @click="reset()" class="btn btn-brand-03 mr-2">Reset </a>
                        </div>
                        <div
                          class="col-lg-6 mb-2 text-right"
                          v-if="access.can_create == 1"
                        >
                          <button
                            type="button"
                            id="save-btn"
                            @click="
                              $router.push('/add-payment/' + this.loanApplication.uuid)
                            "
                            class="btn btn-brand-01 wb-save-btn"
                          >
                            <i class="fa fa-plus"></i>&nbsp; Add Payment
                          </button>
                        </div>
                      </div>
                      <div class="table-responsive" style="min-height: auto">
                        <table class="table table-primary mg-b-0">
                          <thead>
                            <tr>
                              <th>Installments</th>
                              <th>Due Date</th>
                              <th>Paid Date</th>
                              <th>Collector</th>
                              <th>Mobile Number</th>
                              <th>Amount Due</th>
                              <th>Amount Paid</th>
                            </tr>
                          </thead>
                          <tbody v-if="statements.length > 0">
                            <tr v-for="item in statements" :key="item.id">
                              <td :class="item.colorText">
                                <span :class="item.text_color">
                                  Installment {{ item.showLevel }}
                                  <strong>#{{ item?.emi_seq_no }}</strong>
                                  {{
                                    $helperService.getFormattedDateOnly(item.due_date)
                                  }}</span
                                >
                              </td>
                              <td>
                                {{ $helperService.getFormattedDateOnly(item.due_date) }}
                              </td>
                              <td v-if="item.paid_at != null">
                                {{ $helperService.getFormattedDateOnly(item.paid_at) }}
                              </td>
                              <td v-else>-</td>
                              <td>{{ item?.collector?.name }}</td>
                              <td>{{ item?.collector?.mobile_number }}</td>
                              <td class="red-text" v-if="item.due_amount != null">
                                {{
                                  $helperService.getFormattedCurrency(item?.due_amount)
                                }}
                              </td>

                              <td class="green-text">₹ {{ item.paid_amount }}</td>
                            </tr>
                          </tbody>
                          <tr v-else>
                            <td colspan="7" class="text-center">
                              <div
                                class="spinner-border spinner-color"
                                v-if="loader"
                                role="status"
                              ></div>
                              <h5 v-else>Sorry, There is no record found.</h5>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="tab_4"
                  role="tabpanel"
                  aria-labelledby="tab-4"
                  class="tab-pane fade"
                >
                  <div class="worker_serch_warp">
                    <!-- <div class="row">
                      <div class="col-lg-12 mb-2 text-right">
                        <button type="button" id="save-btn"
                          @click="$router.push('/add-payment/' + this.loanApplication.uuid)"
                          class="btn btn-brand-01 wb-save-btn">
                          <i class="fa fa-plus"></i>&nbsp; Add Payment
                        </button>
                      </div>
                    </div> -->
                  </div>
                  <!-- <div class="card rounded-5">
                    <div class="card-body p-3 p-lg-3">
                      <div class="col-lg-12 text-right mb-3" v-if="access.can_create == 1">
                        <button type="button" class="btn btn-brand-01 wb-save-btn mr-3" v-if="showShetelment"
                          id="update-password" @click="settlement('SET')">
                          Settlement
                        </button>
                        <button type="button" class="btn btn-brand-01 wb-save-btn" id="update-password"
                          @click="settlement('CASE')" v-if="application_status == 'DISBURSED'">
                          Raise Legal Case
                        </button>
                      </div>
                      <div class="table-responsive" style="min-height: auto">
                        <table class="table table-primary mg-b-0">
                          <thead>
                            <tr>
                              <th>Transaction Date</th>
                              <th>UTR No</th>
                              <th>Payment Mode</th>
                              <th>Trx Amount</th>
                              <th>Payment Image</th>
                              <th>Status</th>
                              <th>Updated By</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody v-if="transactions.length > 0">
                            <tr v-for="(item, i) in transactions" :key="item.id">
                              <td>
                                {{ $helperService.getFormattedDateOnly(item?.payment_date) }}
                              </td>
                              <td>{{ item?.utr_number }}</td>
                              <td>{{ $helperService.getTitleCase(item?.payment_mode) }}</td>
                              <td>{{ $helperService.getFormattedCurrency(item?.trx_amount) }}</td>
                              <td v-if="item.image != null">
                                <img :src="item.image" width="50" class="img-fluid" @click="openImage(item.image, i)" />
                              </td>
                              <td v-else>-</td>
                             
                              <td v-if="item?.status == 'REJECTED'"><span class="text-danger"> {{
                                $helperService.getTitleCase(item?.status) }} </span> </td>
                              <td v-if="item?.status == 'PAYMENT_VERIFICATION_PENDING'"><span class="text-warning">{{
                                $helperService.getTitleCase(item?.status) }} </span> </td>
                              <td v-if="item?.status == 'VERIFIED'"><span class="text-success">{{
                                $helperService.getTitleCase(item?.status) }} </span> </td>
                              <td v-if="item?.status == 'PENDING'"><span class="text-secondary">{{
                                $helperService.getTitleCase(item?.status) }} </span> </td>
                              <td>
                                <span v-if="item?.rejected_by">
                                  {{ item?.rejected_by?.name }}
                                </span>
                                <span v-else></span>
                                <span v-if="item?.approved_by">
                                  {{ item?.approved_by?.name }}
                                </span>
                                <span v-else></span>
                              </td>
                              <td v-if="item.status != 'REJECTED'">
                                <div class="btn-group action-dd">
                                  <button type="button" class="btn" data-toggle="dropdown" aria-expanded="false">
                                    <img src="/static/img/three-dots.svg" alt="" />
                                  </button>
                                  <div class="dropdown-menu dropdown-menu-right">
                                    <button class="dropdown-item" type="button">
                                      <a href="javascript:void(0)" @click="openApprovedModal('VERIFIED', item.uuid)"
                                        class=" mx-2  "
                                        v-if="item?.status != 'REJECTED' && item?.status != 'VERIFIED'">Approved</a>
                                    </button>
                                    <button class="dropdown-item" type="button">
                                      <a href="javascript:void(0)" @click="openApprovedModal('REJECTED', item.uuid)"
                                        class=" mx-2 "
                                        v-if="item?.status != 'REJECTED' && item?.status != 'VERIFIED'">Rejected</a>
                                    </button>
                                    <button class="dropdown-item" title="Notes" type="button">
                                      <a href="javascript:void(0)" class="btn mx-2" v-if="item?.status == 'VERIFIED'"
                                        @click="openRevokeModal(item.uuid)">Revoke</a>
                                    </button>
                                  </div>
                                </div>
                              </td>
                              <td v-else></td>
                            </tr>
                          </tbody>
                          <tr v-else>
                            <td colspan="10" class="text-center">
                              <div class="spinner-border spinner-color" v-if="loader" role="status"></div>
                              <h5 v-else>Sorry, There is no record found.</h5>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div> -->
                  <!-- New Added balance sheet -->
                  <div class="card rounded-5">
                    <div class="card-body p-3 p-lg-3">
                      <div
                        class="col-lg-12 text-right mb-3"
                        v-if="access.can_update == 1"
                      >
                        <button
                          type="button"
                          class="btn btn-brand-01 wb-save-btn mr-3"
                          v-if="showShetelment"
                          id="update-password"
                          @click="settlement('SET')"
                        >
                          Settlement
                        </button>
                        <button
                          type="button"
                          class="btn btn-brand-01 wb-save-btn"
                          id="update-password"
                          @click="settlement('CASE')"
                          v-if="application_status == 'DISBURSED'"
                        >
                          Raise Legal Case
                        </button>
                      </div>
                      <div class="table-responsive" style="min-height: auto">
                        <table class="table table-primary mg-b-0">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Title</th>
                              <th>Credit</th>
                              <th>Debit</th>
                            </tr>
                          </thead>
                          <tbody v-if="transactions.length > 0">
                            <tr v-for="item in transactions" :key="item.id">
                              <td>
                                {{ $helperService.getFormattedDateOnly(item?.DATE) }}
                              </td>
                              <td>{{ item?.title }}</td>
                              <td v-if="item.credit != '0'">
                                {{ $helperService.getFormattedCurrency(item?.credit) }}
                              </td>
                              <td v-else></td>
                              <td v-if="item.debit != '0'">
                                {{ $helperService.getFormattedCurrency(item?.debit) }}
                              </td>
                              <td v-else></td>
                            </tr>

                            <tr v-if="transactions.length > 0">
                              <td
                                colspan="2"
                                style="
                                  font-weight: 600;
                                  border: 1px solid white;
                                  border-collapse: collapse 10px;
                                  background-color: #96d4d4;
                                "
                                class="text-right"
                              >
                                Sub Total:
                              </td>
                              <td
                                class="text-right"
                                style="
                                  font-weight: 600;
                                  border: 1px solid white;
                                  border-collapse: collapse 10px;
                                  background-color: #96d4d4;
                                "
                              >
                                {{ $helperService.getFormattedCurrency(credit_total) }}
                              </td>
                              <td
                                class="text-right"
                                style="
                                  font-weight: 600;
                                  border: 1px solid white;
                                  border-collapse: collapse 10px;
                                  background-color: #96d4d4;
                                "
                              >
                                {{ $helperService.getFormattedCurrency(debit_total) }}
                              </td>
                            </tr>
                            <tr v-if="transactions.length > 0">
                              <td
                                colspan="2"
                                style="
                                  font-weight: 600;
                                  border: 1px solid white;
                                  border-collapse: collapse 10px;
                                  background-color: #96d4d4;
                                "
                                class="text-right"
                              >
                                Balance:
                              </td>
                              <td
                                colspan="2"
                                class="text-right"
                                style="
                                  font-weight: 600;
                                  border: 1px solid white;
                                  border-collapse: collapse 10px;
                                  background-color: #96d4d4;
                                "
                              >
                                {{
                                  $helperService.getFormattedCurrency(total_due_amount)
                                }}
                              </td>
                            </tr>
                          </tbody>
                          <tr v-else>
                            <td colspan="4" class="text-center">
                              <div
                                class="spinner-border spinner-color"
                                v-if="loader"
                                role="status"
                              ></div>
                              <h5 v-else>Sorry, There is no record found.</h5>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="viewModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ title }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12 col-sm-12">
                <img :src="url" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade wb-modal-wrapper"
      id="uploadLoanKycDocs"
      tabindex="-1"
      role="dialog"
      aria-labelledby="uploadLoanKycDocs"
      aria-hidden="true"
      style="pointer-events: none"
    >
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <a class="close mr-3 mt-3 text-right" @click="closeModal()" aria-label="Close">
            <img src="/static/img/close-icon.svg" width="20" />
          </a>
          <div class="modal-header justify-content-center">
            <h5 class="modal-title font-22">
              <span>Upload Document</span>
            </h5>
          </div>
          <Form
            @submit="onUploadkycDetails"
            class="columns column is-multiline is-12"
            ref="onUploadkycDetails"
          >
            <div class="modal-body form-style pb-0 px-lg-5">
              <div class="signin-form">
                <div class="col-lg-12 mb-4">
                  <label
                    >Document Type
                    <span class="text-danger">*</span>
                  </label>
                  <Field
                    v-slot="{field}"
                    name="document_type"
                    rules="required:document type,true"
                    :validateOnInput="true"
                    v-model="loan_title"
                  >
                    <Multiselect
                      v-bind="field"
                      ref="document_type_multiselect"
                      mode="single"
                      trackBy="title"
                      label="title"
                      valueProp="title"
                      placeholder="Select Document Type"
                      class="form-control"
                      v-model="loan_title"
                      :options="documentList"
                      :searchable="true"
                      :createOption="true"
                    />
                  </Field>
                  <ErrorMessage name="document_type" class="validation-msg" />
                </div>

                <div class="col-lg-12 mb-4" v-if="loan_title == 'Other'">
                  <div class="form-group">
                    <label
                      >Document Title
                      <span class="text-danger">*</span>
                    </label>
                    <Field
                      name="loan_title"
                      v-model="document_narration"
                      class="form-control"
                      rules="required:document title"
                      :validateOnInput="true"
                      type="text"
                      placeholder="Document Title"
                      autocapitalize="false"
                    />
                    <ErrorMessage name="loan_title" class="validation-msg" />
                  </div>
                </div>

                <div class="col-lg-12 mb-4">
                  <div class="form-group">
                    <label
                      >Document Type
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="wb-browse-file"
                      style="cursor: pointer"
                      @click="selectFile($event)"
                    >
                      <Field
                        name="upload_file"
                        v-model="fileName"
                        type="text"
                        placeholder="Browse File"
                        class="form-control"
                        :disabled="disabled == 1"
                      />
                      <Field
                        id="selectFile"
                        name="upload_file"
                        accept=".doc, .docx,.ppt, .pptx,.txt,.pdf,.jpeg,.jpg,.png,.webp"
                        style="display: none"
                        type="file"
                        placeholder="Browse File"
                        @change="onDocumentChange($event)"
                        class="form-control"
                      />
                      <a style="cursor: pointer" @click="selectFile($event)"
                        ><img src="/static/img/browse-icon.svg" alt=""
                      /></a>
                    </div>
                    <ErrorMessage name="upload_file" class="validation-msg" />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer border-0 justify-content-center mb-3">
              <button
                id="cancel-btn"
                @click="closeModal()"
                type="button"
                class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                class="btn btn-brand-01 wb-save-btn"
                id="update-password"
              >
                Save
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>

    <div
      class="modal fade wb-modal-wrapper"
      id="openRejectNarration"
      tabindex="-1"
      role="dialog"
      aria-labelledby="openRejectNarration"
      aria-hidden="true"
      style="pointer-events: none"
    >
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <a
            class="close mr-3 mt-3 text-right"
            @click="closeNarrationModal()"
            aria-label="Close"
          >
            <img src="/static/img/close-icon.svg" width="20" />
          </a>
          <div class="modal-header justify-content-center">
            <h5 class="modal-title font-22" v-if="type == 'SET'">
              <span>Settlement Amount</span>
            </h5>
            <h5 class="modal-title font-22" v-else>
              <span>Raise Legal Case</span>
            </h5>
          </div>
          <Form
            @submit="onAddRejectNarration"
            class="columns column is-multiline is-12"
            ref="onAddRejectNarration"
          >
            <div class="modal-body form-style pb-0 px-lg-5">
              <div class="signin-form">
                <div class="form-group" v-if="type == 'SET'">
                  <label
                    >Settlement Amount
                    <span class="text-danger">*</span>
                  </label>
                  <Field
                    name="settlement_amount"
                    class="form-control"
                    id="settlement_amount"
                    rules="required:settlement amount|numeric"
                    :validateOnInput="true"
                    v-model="settlement_amount"
                    type="text"
                    placeholder="Settlement Amount"
                    autocapitalize="false"
                  />
                  <ErrorMessage name="settlement_amount" class="validation-msg" />
                </div>

                <div class="form-group">
                  <label
                    >Comment
                    <span class="text-danger">*</span>
                  </label>
                  <Field
                    v-slot="{field}"
                    name="comment"
                    rules="required:comment,true"
                    :validateOnInput="true"
                    v-model="comment"
                  >
                    <textarea
                      v-bind="field"
                      rows="5"
                      cols="5"
                      valueProp="id"
                      placeholder="Enter Comment"
                      class="form-control"
                      v-model="comment"
                    />
                  </Field>
                  <ErrorMessage name="comment" class="validation-msg" />
                </div>
              </div>
            </div>
            <div class="modal-footer border-0 justify-content-center mb-3">
              <button
                type="submit"
                class="btn btn-brand-01 wb-cancel-btn"
                id="status-btn"
              >
                Submit
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
    <div
      class="modal fade wb-modal-wrapper"
      id="ApprovedModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="ApprovedModal"
      aria-hidden="true"
      style="pointer-events: none"
    >
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <a
            class="close mr-3 mt-3 text-right"
            @click="closeApproveModal()"
            aria-label="Close"
          >
            <img src="/static/img/close-icon.svg" width="20" />
          </a>
          <div
            class="modal-header justify-content-center"
            v-if="reason_type == 'REJECTED'"
          >
            <h5 class="modal-title font-22">
              <span>Reason</span>
            </h5>
          </div>
          <div
            class="modal-header justify-content-center"
            v-if="reason_type == 'VERIFIED'"
          >
            <h5 class="modal-title font-22">
              <span>Confirmation</span>
            </h5>
          </div>
          <div class="modal-body text-center" v-if="reason_type == 'VERIFIED'">
            <h5>Are you sure you want to verified ?</h5>
          </div>
          <div
            class="modal-footer border-0 justify-content-center mb-3"
            v-if="reason_type == 'VERIFIED'"
          >
            <button
              id="cancel-btn"
              @click="closeApproveModal()"
              type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-brand-01 wb-cancel-btn"
              id="approve-btn"
              @click="onApproveTransaction"
            >
              Verified
            </button>
          </div>
          <Form
            @submit="onApproveTransaction"
            class="columns column is-multiline is-12"
            ref="onApproveTransaction"
            v-if="reason_type == 'REJECTED'"
          >
            <div class="modal-body form-style pb-0 px-lg-5">
              <div class="signin-form">
                <div class="form-group">
                  <label
                    >Reason
                    <span class="text-danger">*</span>
                  </label>
                  <Field
                    v-slot="{field}"
                    name="level"
                    rules="required:reason,true"
                    :validateOnInput="true"
                    v-model="narration"
                  >
                    <textarea
                      v-bind="field"
                      rows="5"
                      cols="5"
                      valueProp="id"
                      placeholder="Enter Reject Reason"
                      class="form-control"
                      v-model="narration"
                    />
                  </Field>
                  <ErrorMessage name="level" class="validation-msg" />
                </div>
              </div>
            </div>
            <div class="modal-footer border-0 justify-content-center mb-3">
              <button
                id="cancel-btn"
                @click="closeApproveModal()"
                type="button"
                class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                class="btn btn-danger wb-cancel-btn"
                id="approve-btn"
                v-if="reason_type == 'REJECTED'"
              >
                Reject
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
    <div
      class="modal fade wb-modal-wrapper"
      id="openRevokeModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="openRevokeModal"
      aria-hidden="true"
      style="pointer-events: none"
    >
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <a
            class="close mr-3 mt-3 text-right"
            @click="closeRevokeModal()"
            aria-label="Close"
          >
            <img src="/static/img/close-icon.svg" width="20" />
          </a>
          <div class="modal-header justify-content-center">
            <h5 class="modal-title font-22">
              <span>Confirmation</span>
            </h5>
          </div>
          <div class="modal-body">
            <Form
              @submit="OnSubmitRevoke"
              class="columns column is-multiline is-12"
              ref="onAddRejectNarration"
            >
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <div class="">
                    <label>
                      Revoke Reason
                      <span class="text-danger">*</span>
                    </label>
                    <Field
                      v-slot="{field}"
                      name="revoke_narration"
                      rules="required:reason,true"
                      :validateOnInput="true"
                      v-model="revoke_narration"
                    >
                      <textarea
                        v-bind="field"
                        rows="5"
                        cols="5"
                        valueProp="id"
                        placeholder="Enter Reject Reason"
                        class="form-control"
                        v-model="revoke_narration"
                      ></textarea>
                    </Field>
                    <ErrorMessage name="revoke_narration" class="validation-msg" />
                  </div>
                </div>
              </div>
              <div class="modal-footer border-0 justify-content-center mb-3">
                <button
                  id="cancel-btn"
                  @click="closeRevokeModal()"
                  type="button"
                  class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  class="btn btn-brand-01 wb-cancel-btn"
                  id="revoke-btn"
                >
                  Revoke
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade wb-modal-wrapper"
      id="openGooglemap"
      tabindex="-1"
      role="dialog"
      aria-labelledby="openGooglemap"
      aria-hidden="true"
      style="pointer-events: none"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <a
            class="close mr-3 mt-3 text-right"
            @click="closeMapModal()"
            aria-label="Close"
          >
            <img src="/static/img/close-icon.svg" width="20" />
          </a>
          <div class="modal-header justify-content-center">
            <h5 class="modal-title font-22">
              <span>Map</span>
            </h5>
          </div>
          <div class="modal-body form-style pb-0 px-lg-5">
            <div class="row">
              <div class="col-lg-12">
                <iframe
                  :src="
                    'https://www.google.com/maps?q=' +
                    maps?.latitude +
                    ',' +
                    maps?.longitude +
                    '&hl=en&z=14&amp;output=embed'
                  "
                  style="width: 100%; height: 500px !important"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  title="Google Map"
                ></iframe>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button
              id="cancel-btn"
              @click="closeMapModal()"
              type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <vue-easy-lightbox
    :visible="visibleRef"
    :imgs="showableImage"
    :index="indexRef"
    @hide="onHide"
  ></vue-easy-lightbox>
  <div
    class="modal fade wb-modal-wrapper"
    id="showDownloadAlert"
    tabindex="-1"
    role="dialog"
    aria-labelledby="showDownloadAlert"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-md modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="closeDownloadAlertPopup()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Download Alert</span>
          </h5>
        </div>
        <div class="modal-body form-style pb-0 px-lg-5">
          <div class="row">
            <div class="col-lg-12">
              Loan Agreement/Sanction Letter or Loan schedule will be available soon.
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button
            id="cancel-btn"
            @click="closeDownloadAlertPopup()"
            type="button"
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade wb-modal-wrapper"
    id="onUpdateDates"
    tabindex="-1"
    role="dialog"
    aria-labelledby="onUpdateDates"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="onCloseCycleDateModal()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Update 1st EMI Date</span>
          </h5>
        </div>
        <Form
          @submit="onUpdateDateDetails"
          class="columns column is-multiline is-12"
          ref="onUpdateCycleDetails"
        >
          <div class="modal-body form-style pb-0 px-lg-5">
            <div class="signin-form">
              <div class="form-group">
                <label
                  >Disbursement Date
                  <span class="text-danger">*</span>
                </label>
                <Field
                  name="disbursed_at"
                  class="form-control"
                  id="disbursed_at"
                  rules="required:Disbursement Date"
                  :validateOnInput="true"
                  v-model="disbursed_at"
                  type="date"
                  placeholder="Disbursement Date"
                  autocapitalize="false"
                />
                <ErrorMessage name="disbursed_at" class="validation-msg" />
              </div>
              <div class="form-group">
                <label
                  >Cycle Date
                  <span class="text-danger">*</span>
                </label>
                <Field
                  name="cycle_start_date"
                  class="form-control"
                  id="cycle_start_date"
                  rules="required:Cycle date"
                  :validateOnInput="true"
                  v-model="cycle_start_date"
                  type="date"
                  placeholder="Cycle Date"
                  autocapitalize="false"
                  @change="setActualDate($event)"
                />
                <ErrorMessage name="cycle_start_date" class="validation-msg" />
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button
              id="cancel-btn"
              @click="onCloseCycleDateModal()"
              type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
            >
              Cancel
            </button>
            <button type="submit" class="btn btn-brand-01 wb-save-btn" id="update-date">
              Save
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <div
    class="modal fade wb-modal-wrapper"
    id="uploadKycDocs"
    tabindex="-1"
    role="dialog"
    aria-labelledby="uploadKycDocs"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="onCloseUplaodKycModal()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Upload Document</span>
          </h5>
        </div>
        <Form
          @submit="onUploadkycDetails"
          class="columns column is-multiline is-12"
          ref="onUploadkycDetails"
        >
          <div class="modal-body form-style pb-0 px-lg-5">
            <div class="signin-form">
              <div class="col-lg-12 mb-4">
                <label
                  >Document Type
                  <span class="text-danger">*</span>
                </label>
                <Field
                  v-slot="{field}"
                  name="document_type"
                  rules="required:document type,true"
                  :validateOnInput="true"
                  v-model="kycDocuments.doc_id"
                >
                  <Multiselect
                    v-bind="field"
                    ref="document_type_multiselect"
                    mode="single"
                    trackBy="title"
                    label="title"
                    valueProp="uuid"
                    @select="afterValueChange()"
                    placeholder="Select Document Type"
                    class="form-control"
                    v-model="kycDocuments.doc_id"
                    :options="kycDocumentList"
                    :searchable="true"
                    :createOption="true"
                  />
                </Field>
                <ErrorMessage name="document_type" class="validation-msg" />
              </div>

              <div class="col-lg-12 mb-4">
                <div class="form-group">
                  <label>
                    Document Number
                    <span class="text-danger" v-if="is_optional == 0">*</span>
                  </label>
                  <Field
                    name="doc_number"
                    v-model="kycDocuments.doc_no"
                    class="form-control"
                    :rules="
                      is_optional == 0
                        ? documentTitle == 'AADHAAR_CARD'
                          ? 'required:document number,true|aadhaar'
                          : documentTitle == 'PAN_CARD'
                          ? 'required:document number,true|PAN'
                          : ''
                        : ''
                    "
                    :validateOnInput="true"
                    type="text"
                    placeholder="Document number"
                    autocapitalize="false"
                  />
                  <ErrorMessage name="doc_number" class="validation-msg" />
                </div>
              </div>

              <div class="col-lg-12 mb-4" v-if="has_additional_info == 1">
                <div class="form-group">
                  <label
                    >Issue Date
                    <span class="text-danger">*</span>
                  </label>
                  <Field
                    name="issue_date"
                    class="form-control"
                    v-slot="{date}"
                    :rules="is_optional == 0 ? 'required:issue date,true' : ''"
                    id="issue_date"
                    v-model="kycDocuments.issue_date"
                  >
                    <VueDatePicker
                      v-bind="date"
                      placeholder="Select Issue Date "
                      style="height: 46px !important"
                      v-model="kycDocuments.issue_date"
                      :type="boolean"
                      :format="'dd/MM/yyyy'"
                      :default="true"
                      :enable-time-picker="false"
                    >
                    </VueDatePicker>
                  </Field>
                  <ErrorMessage name="issue_date" class="validation-msg" />
                </div>
              </div>
              <div class="col-lg-12 mb-4" v-if="has_additional_info == 1">
                <div class="form-group">
                  <label
                    >Expiry Date
                    <span class="text-danger">*</span>
                  </label>
                  <Field
                    name="expired_date"
                    class="form-control"
                    v-slot="{date}"
                    :rules="is_optional == 0 ? 'required:expiry date,true' : ''"
                    id="expired_date"
                    v-model="kycDocuments.expiry_date"
                  >
                    <VueDatePicker
                      v-bind="date"
                      placeholder="Select Expired Date "
                      style="height: 46px !important"
                      v-model="kycDocuments.expiry_date"
                      :type="boolean"
                      :format="'dd/MM/yyyy'"
                      :default="true"
                      :enable-time-picker="false"
                    >
                    </VueDatePicker>
                  </Field>
                  <ErrorMessage name="expired_date" class="validation-msg" />
                </div>
              </div>
              <div class="col-lg-12 mb-4">
                <div class="form-group">
                  <label
                    >Document
                    <!-- <span class="text-danger">*</span> -->
                  </label>
                  <div
                    class="wb-browse-file"
                    style="cursor: pointer"
                    @click="selectKycFile($event)"
                  >
                    <Field
                      name="upload_kyc_file"
                      v-model="kycFileName"
                      type="text"
                      placeholder="Browse File"
                      class="form-control"
                      :disabled="disabled == 1"
                    />
                    <Field
                      id="selectKycFile"
                      name="upload_kyc_file"
                      accept=".doc, .docx,.ppt, .pptx,.txt,.pdf,.jpeg,.jpg,.png,.webp"
                      style="display: none"
                      type="file"
                      placeholder="Browse File"
                      @change="onKycDocumentChange($event)"
                      class="form-control"
                    />
                    <a style="cursor: pointer" @click="selectKycFile($event)"
                      ><img src="/static/img/browse-icon.svg" alt=""
                    /></a>
                  </div>
                  <ErrorMessage name="upload_kyc_file" class="validation-msg" />
                </div>
                <a
                  v-if="downloadKycDocument != null"
                  style="cursor: pointer"
                  @click="downloadPdf(downloadKycDocument, 'kyc Document')"
                  ><img
                    src="/static/img/download-icon.svg"
                    class="mx-3"
                    alt=""
                  />Download</a
                >
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button
              id="cancel-btn"
              @click="onCloseUplaodKycModal()"
              type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-brand-01 wb-save-btn"
              id="loan-kyc-document"
            >
              Save
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <div
    class="modal fade wb-modal-wrapper"
    id="openDeleteModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="openDeleteModel"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="closeDeleteModal()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Confirmation</span>
          </h5>
        </div>
        <div class="modal-body text-center">
          <h5>Are you sure you want to delete?</h5>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button
            type="button"
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
            @click="closeDeleteModal()"
          >
            No
          </button>
          <button
            type="button"
            @click="onDeleteKycDocumentAPI()"
            class="btn btn-brand-01 wb-cancel-btn"
            id="delete_kyc_document"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.multiselect-tags-search {
  top: -1px !important;
}

.text-white {
  color: white !important;
}

.text-black {
  color: black !important;
}
</style>
<script>
import VueEasyLightbox from "vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js"
import "vue-easy-lightbox/external-css/vue-easy-lightbox.css"
import {ref} from "vue"
import Multiselect from "@vueform/multiselect"
import "@vueform/multiselect/themes/default.css"
import VueDatePicker from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css"
import $ from "jquery"
import {Form, Field, ErrorMessage} from "vee-validate"
import moment from "moment"
export default {
  name: "AddEditLoanApplication",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    VueDatePicker,
    VueEasyLightbox,
  },
  data() {
    return {
      id: this.$route.params.id,
      loan_id: "",
      loader: false,
      loanApplication: {},
      user: {},
      loanDocument: [],
      documents: [],
      comment: "",
      reason_type: "",
      application_status: "",
      guarantorList: [],
      loanTypeList: [],
      url: "",
      title: "",
      type: "",
      settlement_amount: "",
      currentPage: 1,
      totalPage: 0,
      itemsPerPage: 10,
      list: [],
      filterObj: {
        keyword: "",
        offset: 1,
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
        branch_id: "",
        status: "",
      },
      statements: [],
      settlements: null,
      documentList: [],
      document_narration: "",
      kycDcoument: "",
      checklist: [],
      transactions: [],
      date: "",
      showableImage: "",
      visibleRef: ref(false),
      indexRef: ref(0),
      listType: "",
      transaction_id: "",
      revoke_narration: "",
      showShetelment: false,
      maps: null,
      colorText: "",
      showLevel: "",
      emi_seq_no: "",
      emi_due_date: "",
      text_color: "text-white",
      cycle_start_date: "",
      disbursed_at: "",
      kycDocumentList: [],
      kycDocuments: {
        doc_id: "",
        doc_no: "",
        issue_date: "",
        expiry_date: "",
      },
      documentTitle: "",
      kycDocumentFile: "",
      kycFileName: "",
      has_additional_info: "",
      is_optional: "",
      kycLoanDocuments: [],
      downloadKycDocument: null,
      kycDocumentType: "ADD",
      kycDocummentUuid: "",
      requestedLoanAmont: "",
      sanctionAmount: "",
      sanctionNarration: "",
      credit_total: 0,
      debit_total: 0,
      total_due_amount: 0,
      access: {
        can_read: 1,
        can_create: 1,
        can_update: 1,
        can_print: 1,
        can_delete: 1,
      },
      renew_loan: {
        loan_amount: "",
        loan_type_id: "",
        description: "",
      },
    }
  },
  computed: {
    branchList() {
      return this.$storeService.getters.getBranches
    },
    hasFullAccess() {
      return this.$storeService.getters.getHasFullAccess
    },
  },
  mounted() {
    if (this.id) {
      this.getLOanApplicationInfo()
    }
  },
  methods: {
    openDeleteModal(item) {
      this.delete_id = item?.id
      window.$("#openDeleteModel").modal("show")
    },
    closeDeleteModal() {
      this.delete_id = null
      window.$("#openDeleteModel").modal("hide")
    },
    onDeleteKycDocumentAPI() {
      var method = "DELETE"
      this.$api
        .webRequest({
          _method: method,
          _action: "loan/" + this.id + "/kyc-document/" + this.delete_id,
          _button_id: "delete_kyc_document",
        })
        .then((res) => {
          if (res) {
            this.getLOanApplicationInfo()
            this.closeDeleteModal()
            this.$toast.success(res.message, {
              position: "top-right",
            })
          }
        })
        .catch((e) => {
          this.closeDeleteModal()
          this.$toast.error(e.message, {
            position: "top-right",
          })
        })
    },
    setActualDate(e) {
      console.log(this.disbursed_at);
      var date = new Date(e.target.value);
      this.cycle_start_date = "";

      setTimeout(() => {
        let nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
        let lastDay = new Date(nextMonth - 1);

        if (date.getMonth() === 1 && date.getDate() > 15) {
          this.cycle_start_date = `${date.getFullYear()}-02-${lastDay.getDate()}`;
        } else if (
          date.getMonth() === 1 &&
          date.getDate() === lastDay.getDate()
        ) {
          this.cycle_start_date = `${date.getFullYear()}-02-${lastDay.getDate()}`;
        } else if (date.getDate() === 30) {
          this.cycle_start_date = `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-30`;
        } else if (date.getDate() === lastDay.getDate()) {
          let nextMonth15 = new Date(
            date.getFullYear(),
            date.getMonth() + 1,
            16
          );
          this.cycle_start_date = nextMonth15.toISOString().split("T")[0];
        } else if (date.getDate() > 15) {
          this.cycle_start_date = `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-30`;
        } else {
          let currentMonth = (date.getMonth() + 1).toString().padStart(2, "0");
          this.cycle_start_date = `${date.getFullYear()}-${currentMonth}-15`;
        }
        console.log(this.cycle_start_date);
      }, 1000);
    },
    onUpdateDate() {
      this.cycle_start_date = this.loanApplication.cycle_start_date
      this.disbursed_at = this.loanApplication.disbursed_at
      window.$("#onUpdateDates").modal("show")
    },

    onCloseCycleDateModal() {
      window.$("#onUpdateDates").modal("hide")
      this.onClearForm("onUpdateCycleDetails")
    },
    traversBasedOnStatus(item) {
      if (item.status == "REJECTED") {
        this.$router.push("/view-loan-application/" + item.uuid)
      }
      if (item.status == "FINAL_APPROVAL") {
        this.$router.push("/view-loan-application/" + item.uuid)
      }
      if (item.status == "DOCUMENT_VERIFIED") {
        this.$router.push("/view-verified-loan-application/" + item.uuid)
      }
      if (item.status == "PENDING_FOR_VERIFICATION") {
        this.$router.push("/view-loan-application/" + item.uuid)
      }
      if (item.status == "PENDING") {
        this.$router.push("/view-loan-application/" + item.uuid)
      }
      if (item.status == "PENDING") {
        this.$router.push("/view-loan-application/" + item.uuid)
      }
    },
    OpenMapModel(item) {
      this.maps = item
      window.$("#openGooglemap").modal("show")
    },
    closeMapModal() {
      this.maps = null
      window.$("#openGooglemap").modal("hide")
    },
    getDocumentList(id) {
      this.documentList = []
      var method = "GET"
      this.$api
        .webRequest({
          _method: method,
          _action: "select/loan/" + id + "/documents",
        })
        .then((res) => {
          this.documentList = res.list
          var obj = {id: 0, title: "Other"}
          this.documentList.push(obj)
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    onUplaodDocument() {
      window.$("#uploadLoanKycDocs").modal("show")
    },
    closeModal() {
      window.$("#uploadLoanKycDocs").modal("hide")
      this.onClearForm("onUploadkycDetails")
    },
    openApprovedModal(status, id) {
      this.reason_type = status
      this.transaction_id = id
      window.$("#ApprovedModal").modal("show")
    },
    closeApproveModal() {
      this.reason_type = ""
      this.transaction_id = null
      window.$("#ApprovedModal").modal("hide")
    },
    openRevokeModal(id) {
      this.transaction_id = id
      window.$("#openRevokeModal").modal("show")
    },
    closeRevokeModal() {
      this.transaction_id = null
      window.$("#openRevokeModal").modal("hide")
    },
    settlement(type) {
      this.type = type
      window.$("#openRejectNarration").modal("show")
    },
    closeNarrationModal() {
      this.comment = null
      this.reason_type = null
      window.$("#openRejectNarration").modal("hide")
      this.onClearForm("onAddRejectNarration")
    },
    onClearForm(formRefName) {
      this.$refs[formRefName].resetForm()
    },
    getLOanApplicationInfo() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "disbursed/loan/" + this.id,
        })
        .then((res) => {
          this.access = res?.access
          this.documents = []
          this.kycLoanDocuments = res?.info?.kyc_documents
          this.sanctionAmount = res?.info?.sanction_amount
          this.sanctionNarration = res?.info?.sanction_narration
          this.user = res?.info?.user
          this.settlements = res?.info?.settlement
          this.loanApplication = res?.info
          this.documents = res?.info?.documents
          this.checklist = res?.info?.checklists
          this.loanDocument = res?.info?.documents
          this.application_status = res?.info?.status
          this.guarantorList = res?.info?.guarantors
          this.loan_id = res?.info?.uuid

          if (
            res?.info?.status == "DISBURSED" ||
            res?.info?.status == "SETTLEMENT_REJECTED"
          ) {
            this.showShetelment = true
          }
          // this.statements = res?.info?.installments ? res?.info?.installments : [];
          this.getDocumentList(res?.info?.loan_type_id)
          this.onCheckUserTier(res?.info?.uuid)
        })
        .catch(() => {})
    },

    onUploadLoanDocument() {
      this.$api
        .uploadImageAPI({
          _action: "loan/" + this.id + "/upload/document",
          _key: "image",
          _file: this.kycDcoument,
          _body: {
            title: this.loan_title == "Other" ? this.document_narration : this.loan_title,
          },
        })
        .then((res) => {
          this.$toast.success(res.message, {position: "top-right"})
          this.closeModal()
          this.getLOanApplicationInfo()
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    onAddRejectNarration() {
      var url = ""
      if (this.type == "SET") {
        url = "disbursed/loan/" + this.id + "/settlement"
      } else {
        url = "disbursed/loan/" + this.id + "/legal"
      }
      var button_id = "status-btn"
      this.$api
        .webRequest({
          _method: "POST",
          _action: url,
          _buttonId: button_id,
          _body: {
            settlement_amount: this.settlement_amount,
            comment: this.comment,
          },
        })
        .then((res) => {
          this.closeNarrationModal()
          this.$toast.success(res.message, {position: "top-right"})
          this.getLOanApplicationInfo()
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    download(url, title) {
      this.url = url
      this.title = title
      window.$("#viewModal").modal("show")
    },
    selectFile(e) {
      e.stopImmediatePropagation()
      window.$("#selectFile").click()
    },
    onDocumentChange(e) {
      let files = e.target.files
      if (files.length > 0) {
        this.fileName = files[0].name
        this.kycDcoument = files[0]
      } else {
        this.fileName = null
        this.kycDcoument = null
      }
    },
    pageChange(page) {
      this.getList(page)
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.rolePagination.itemsPerPage
      this.getList(1)
    },
    reset() {
      this.filterObj = {
        keyword: "",
        offset: 1,
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
        level: "",
        status: "",
        branch_ids: "",
      }
      this.date = ""
      this.getList(1, this.listType)
    },
    retry() {
      this.getList(1, this.listType)
    },
    getList(page, type, sortBy, orderBy) {
      this.listType = type
      this.filterObj.offset = page - 1
      this.currentPage = page
      this.filterObj.row_per_page = this.itemsPerPage

      if (sortBy) {
        this.filterObj.sort_by = sortBy
      }
      if (orderBy) {
        this.filterObj.sort_order = orderBy
      }

      if (this.date) {
        this.filterObj.from_date = moment(String(this.date[0])).format("Y-MM-DD")
        this.filterObj.to_date = moment(String(this.date[1])).format("Y-MM-DD")
      } else {
        this.filterObj.from_date = ""
        this.filterObj.to_date = ""
      }

      var url = ""

      if (type == "STATEMENT") {
        this.statements = []
        this.loader = true
        url = "loan/" + this.loanApplication.uuid + "/statement"
      }
      if (type == "TRANSACTION") {
        this.transactions = []
        this.loader = true
        // url = "loan/" + this.loanApplication.uuid + "/transactions";
        url = "loan/" + this.loanApplication.uuid + "/balance-sheets"
      }

      var method = "GET"
      this.$api
        .webRequest({
          _method: method,
          _action: url,
          _body: this.filterObj,
        })
        .then((res) => {
          if (type == "STATEMENT") {
            this.loader = false
            if (res?.info?.installments) {
              this.statements = res.info.installments
              this.statements.forEach((item) => {
                item.showLevel = "EMI Due"
                item.text_color = "text-white"
                item.colorText = "black-box"
                this.checkCurrentEvent(item)
              })
            } else {
              this.statements = []
            }
          }
          if (type == "TRANSACTION") {
            this.loader = false
            this.transactions = res.info.balance_sheet_transactions
            this.credit_total = res?.info?.credit_total
            this.debit_total = res?.info?.debit_total
            this.total_due_amount = res?.info?.total_due_amount
          }
          // res.list = this.list;
          if (page == 1) {
            if (res.count != null && res.count > 0) {
              if (this.$refs.rolePagination) {
                this.$refs.rolePagination.setTotalCount(res.count)
              }
            } else {
              if (this.$refs.rolePagination) {
                this.$refs.rolePagination.setTotalCount(0)
              }
            }
          }
          if (!res.list || res.list.length <= 0) {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setTitle("Sorry, There is no record found.", true)
            }
          } else {
            if (this.$refs.errorComponent) {
              if (this.$refs.errorComponent.isShowMessage()) {
                this.$refs.errorComponent.setTitle()
              }
            }
          }
          if (this.$refs.rolePagination) {
            this.$refs.rolePagination.currentPage = this.currentPage
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setTitle()
          }
        })
    },
    sorting(e, sortBy) {
      var orderBy = "ASC"

      var className = "sorting_asc"
      var classes = e.target.className
      if (classes.includes("sorting_asc")) {
        className = "sorting_desc"
      }

      $(".sorting_asc").removeClass("sorting_asc")
      $(".sorting_desc").removeClass("sorting_desc")

      if (className == "sorting_asc") {
        $(e.target).addClass("sorting_asc").removeClass("sorting_desc")
        orderBy = "ASC"
      } else if (className == "sorting_desc") {
        $(e.target).addClass("sorting_desc").removeClass("sorting_asc")
        orderBy = "DESC"
      }
      this.getList(1, this.listType, sortBy, orderBy)
    },
    openImage(image, index) {
      this.showableImage = image
      this.indexRef = index
      this.visibleRef = true
    },
    onHide() {
      this.visibleRef = false
      this.showableImage = ""
      this.indexRef = ""
    },

    onApproveTransaction() {
      this.$api
        .webRequest({
          _method: "PUT",
          _action: "loan/" + this.id + "/transaction/" + this.transaction_id + "/status",
          _buttonId: "approve-btn",
          _body: {status: this.reason_type, narration: this.narration},
        })
        .then((res) => {
          this.closeApproveModal()
          this.$toast.success(res.message, {position: "top-right"})
          this.getList(1, this.listType)
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    OnSubmitRevoke() {
      this.$api
        .webRequest({
          _method: "PUT",
          _action: "loan/" + this.id + "/transaction/" + this.transaction_id + "/revoke",
          _buttonId: "revoke-btn",
          _body: {narration: this.revoke_narration},
        })
        .then((res) => {
          this.closeRevokeModal()
          this.$toast.success(res.message, {position: "top-right"})
          this.getList(1, this.listType)
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    checkCurrentEvent(item) {
      if (item?.payment_status == "PAID") {
        if (item?.level == "PLATINUM") {
          console.log("item?.level", item?.level)
          item.showLevel = "Platinum"
          item.colorText = "green-box"
        } else if (item?.level == "GOLD") {
          console.log("item?.level", item?.level)
          item.showLevel = "Gold"
          item.colorText = "yellow-box"
        } else if (item?.level == "SILVER") {
          console.log("item?.level", item?.level, item?.emi_seq_no, item?.due_date)
          item.showLevel = "Silver"
          item.colorText = "none-box"
        } else {
          console.log("item?.level", item?.level)
          item.showLevel = "None"
          item.colorText = "secondary"
        }
      } else {
        this.checkOtherValidation(item)
      }
    },
    checkOtherValidation(item) {
      console.log("checkOtherValidation ", item?.level)
      var currentDate = new Date()
      var currDate = moment(currentDate).format("YYYY-MM-DD")
      var dueDate = moment(item?.due_date, "YYYY-MM-DD").format("YYYY-MM-DD")
      console.log("currDate", currDate)
      console.log("dueDate", dueDate)
      if (currDate <= dueDate) {
        console.log("EMI Due")
        item.showLevel = "EMI Due"
        item.text_color = "text-white"
        item.colorText = "black-box"
      } else if (currDate > dueDate) {
        item.showLevel = "EMI Over Due"
        item.text_color = "text-white"
        item.colorText = "red-box"
        console.log("EMI Over Due", item.colorText)
      }
    },
    downloadPdf(url, filename) {
      if (url == null) {
        window.$("#showDownloadAlert").modal("show")
      } else {
        console.log(url, filename)
        var anchorElement = document.createElement("a")
        anchorElement.href = url
        anchorElement.download = filename
        anchorElement.target = "_blank"
        document.body.appendChild(anchorElement)
        console.log(anchorElement)
        anchorElement.click()
        document.body.removeChild(anchorElement)
      }
    },
    closeDownloadAlertPopup() {
      window.$("#showDownloadAlert").modal("hide")
    },
    onUpdateDateDetails() {
      this.$api
        .webRequest({
          _method: "PUT",
          _action: "disbursed/loan/" + this.id,
          _buttonId: "update-date",
          _body: {
            cycle_start_date: this.cycle_start_date,
            disbursed_at: this.disbursed_at,
          },
        })
        .then((res) => {
          this.$toast.success(res.message, {position: "top-right"})
          this.onCloseCycleDateModal()
          this.getLOanApplicationInfo()
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    editKycDocument(item, type) {
      this.kycDocummentUuid = item?.uuid
      this.kycDocumentType = type
      this.kycDocuments = {
        doc_id: item?.doc?.uuid,
        doc_no: item.doc_no,
      }
      if (item?.issue_date != null) {
        this.kycDocuments.issue_date = item?.issue_date
      }
      if (item?.expiry_date != null) {
        this.kycDocuments.expiry_date = item?.expiry_date
      }
      this.kycDocumentList.forEach((obj) => {
          if (obj.uuid == item?.doc?.uuid) {
            this.documentTitle = obj.code
          }
        });
      this.downloadKycDocument = item?.doc_img
      this.kycDcoumentFile = ""
      console.log("edit kyc details : ", item)
      window.$("#uploadKycDocs").modal("show")
    },
    onUplaodKycDocument() {
      window.$("#uploadKycDocs").modal("show")
    },
    onCloseUplaodKycModal() {
      window.$("#uploadKycDocs").modal("hide")
      this.kycDocuments = {
        doc_id: "",
        doc_number: "",
        issue_date: "",
        expiry_date: "",
      }
      this.kycDocumentFile = ""
      this.kycFileName = null
      this.downloadKycDocument = null
      this.kycDocummentUuid = ""
    },
    afterValueChange() {
      if (this.kycDocumentList.length > 0) {
        this.kycDocumentList.forEach((obj) => {
          if (obj.id == this.kycDocuments.doc_id) {
            this.documentTitle = obj.code
            this.has_additional_info = obj.has_additional_info
            this.is_optional = obj.is_optional
            console.log(
              "the value is ",
              obj.title,
              obj.has_additional_info,
              obj.is_optional
            )
          }
        })
      }
    },
    editGuarantor(item) {
      this.$router.push("/edit-guarantor/" + this.id + "/" + item.uuid)
    },
    onGetKycDocument() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "kyc-documents",
        })
        .then((res) => {
          this.kycDocumentList = res.list
        })
        .catch(() => {})
    },
    onUploadkycDetails() {
      if (this.kycDocuments.issue_date) {
        this.kycDocuments.issue_date = moment(
          String(this.kycDocuments.issue_date)
        ).format("Y-MM-DD")
      }
      if (this.kycDocuments.expiry_date) {
        this.kycDocuments.expiry_date = moment(
          String(this.kycDocuments.expiry_date)
        ).format("Y-MM-DD")
      }

      // if (this.kycDocuments) {
      //     console.log("the value of the kyc document is :", this.kycDocuments);
      //     return true;
      // }
      var url = ""
      if (this.kycDocumentType == "EDIT") {
        url = "loan/" + this.id + "/kyc-document/" + this.kycDocummentUuid
      } else {
        url = "loan/" + this.id + "/kyc-document"
      }
      this.$api
        .webRequest({
          _method: "POST",
          _action: url,
          _body: this.kycDocuments,
          _buttonId: "loan-kyc-document",
        })
        .then((res) => {
          this.$toast.success(res.message, {position: "top-right"})
          if (typeof this.kycDcoumentFile == "object") {
            this.onUploadKyCFiles(this.kycDcoumentFile, res?.id)
          } else {
            this.onCloseUplaodKycModal()
            this.getLOanApplicationInfo()
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },

    onUploadKyCFiles(file, uuid) {
      this.$api
        .uploadImageAPI({
          _action: "loan/" + this.id + "/kyc-document/" + uuid + "/upload",
          _key: "file",
          _buttonId: "loan-kyc-document",
          _file: this.kycDcoumentFile,
        })
        .then(() => {
          // this.$toast.success(res.message, { position: "top-right" });
          this.getLOanApplicationInfo()
          this.onCloseUplaodKycModal()
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    selectKycFile(e) {
      e.stopImmediatePropagation()
      window.$("#selectKycFile").click()
    },
    onKycDocumentChange(e) {
      let files = e.target.files
      if (files.length > 0) {
        this.kycFileName = files[0].name
        this.kycDcoumentFile = files[0]
        console.log("kyc file:", this.kycDcoumentFile)
      } else {
        this.kycFileName = null
        this.kycDocumentFile = null
      }
    },
    onAmortization() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "loan/" + this.id + "/adv/statement",
          _buttonId: "amortization",
        })
        .then((res) => {
          if (res) {
            this.downloadPdf(res?.url, res?.file_name)
            this.$toast.success(res.message, {position: "top-right"})
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    save() {
      // console.log("the value is :", this.renew_loan);
      var method = "POST"
      var action = "/renew-loan/" + this.id
      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: this.renew_loan,
          _buttonId: "loan-application-btn",
        })
        .then((res) => {
          this.$toast.success(res.message, {position: "top-right"})
          this.$router.go(-1)
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    onCheckUserTier(loanUuid) {
      if (loanUuid == "" && loanUuid == null && loanUuid == undefined) {
        return false
      }
      this.$api
        .webRequest({
          _method: "GET",
          _action: "select/renew-loan-type/" + loanUuid,
        })
        .then((res) => {
          this.loanTypeList = []
          for (var i = 0; i < res.list.length; i++) {
            var obj = {
              title:
                res.list[i].title +
                " (" +
                this.$helperService.getTitleCase(res.list[i].type) +
                ")" +
                " - " +
                this.$helperService.getTitleCase(res.list[i].level),
              value: res.list[i].id,
            }
            this.loanTypeList.push(obj)
          }
        })
        .catch(() => {})
    },
  },
}
</script>

<style>
.multiselect-tags-search {
  top: -1px !important;
}
</style>

<template>
  <div class="content-body">
    <div class="worker_serch_warp">
      <div class="row d-flex justify-content-between">
        <div class="col-md-4 pr-2">
          <h2 class="content-title">
            <a @click="$router.go(-1)" style="cursor: pointer"><i class="fas fa-angle-left mr-2"></i><span
                v-if="!this.guarantor_uuid">Add Guarantor</span>
              <span v-else>Edit Guarantor</span>
            </a>
          </h2>
        </div>
      </div>
    </div>
    <div class="component-section no-code">
      <div class="row-sm wb-row-gap mx-2">
        <Form @submit="saveGuarantor" class="columns column is-multiline is-12" data-vv-scope="addEditGuarantor"
          ref="addEditGuarantor">
          <div class="card card-body">
            <h4 style="border-bottom: 1px solid #cccccc; line-height: 2">
              Basic Info
            </h4>
            <div class="row">
              <div class="col-lg-4 col-sm-12 pt-2">
                <label>Mobile Number <span class="text-danger">*</span> </label>
                <div class="input-group">
                  <Field name="mobile_number" class="form-control" id="mobile_number"
                    rules="required:mobile number |phone|numeric" :validateOnInput="true"
                    v-model="guarantor.mobile_number" type="text" autoComplete="off" placeholder="Mobile Number"
                    autocapitalize="false" />
                  <div class="input-group-append" v-show="has_search_api_call === true">
                    <div class="input-group-text">
                      <div class="spinner-border mobile_number_spinner text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
                <ErrorMessage name="mobile_number" class="validation-msg" />
              </div>
              <div class="col-lg-4 mb-3 pt-2">
                <label>
                  Name
                  <span class="text-danger">*</span></label>
                <Field name="name" id="name" rules="required:name" class="form-control"
                  v-model="guarantor.guarantor_name" type="text" autoComplete="off" v-bind:placeholder="'Name'" />
                <ErrorMessage name="name" class="validation-msg" />
              </div>
              <div class="col-lg-4 col-sm-6 pt-2">
                <label> Email</label>
                <Field name="email" v-model="guarantor.email" class="form-control" rules="email|email"
                  :validateOnInput="true" type="text" autoComplete="off" placeholder="Email" autocapitalize="false" />
                <ErrorMessage name="email" class="validation-msg" />
              </div>

              <div class="col-lg-4 col-sm-12 pt-2">
                <label>Alt Mobile Number 1 </label>
                <Field name="alt_mobile_number_1" class="form-control" id="alt_mobile_number_1" rules="phone|numeric"
                  :validateOnInput="true" v-model="guarantor.alt_mobile_number_1" type="text" autoComplete="off"
                  placeholder="Alt Mobile Number 1" autocapitalize="false" />
                <ErrorMessage name="alt_mobile_number_1" class="validation-msg" />
              </div>
              <div class="col-lg-4 col-sm-12 pt-2">
                <label>Alt Mobile Number 2 </label>
                <Field name="alt_mobile_number_2" class="form-control" id="alt_mobile_number_2" rules="phone|numeric"
                  :validateOnInput="true" v-model="guarantor.alt_mobile_number_2" type="text" autoComplete="off"
                  placeholder="Alt Mobile Number 2" autocapitalize="false" />
                <ErrorMessage name="alt_mobile_number_2" class="validation-msg" />
              </div>
              <div class="col-lg-4 mb-3 pt-2">
                <label> Gender<span class="text-danger">*</span> </label>
                <div class="">
                  <span class="mr-3">
                    <input class="" name="gender" v-model="guarantor.gender"
                      :checked="guarantor.gender == 'MALE' ? true : false" type="radio" value="MALE" id="male"
                      style="width: 20px; height: auto" />
                    <label class="form-check-label" for="male"> Male </label>
                  </span>
                  <span>
                    <input class="" v-model="guarantor.gender" :checked="guarantor.gender == 'FEMALE' ? true : false"
                      type="radio" name="gender" value="FEMALE" id="female" style="width: 20px; height: auto" />
                    <label class="form-check-label" for="female">
                      Female
                    </label>
                  </span>
                </div>
                <ErrorMessage name="gender" class="validation-msg" />
              </div>
              <div class="col-sm-4 mb-4 pt-2">
                <label>Date of Birth<span class="text-danger">*</span></label>
                <Field name="date_of_birth" class="form-control" v-slot="{ date }"
                  rules="required:date of birth,true|ageValidation" id="date_of_birth"
                  v-model="guarantor.date_of_birth">
                  <VueDatePicker v-bind="date" placeholder="Select Date" style="height: 46px !important"
                    v-model="guarantor.date_of_birth" :type="boolean" :format="'dd/MM/yyyy'" :default="true"
                    :enable-time-picker="false" :max-date="Date.now()">
                  </VueDatePicker>
                </Field>
                <ErrorMessage name="date_of_birth" class="validation-msg" />
              </div>
              <div class="col-lg-4 mb-3 pt-2">
                <label>
                  Occupation
                  <span class="text-danger">*</span></label>
                <Field v-slot="{ field }" name="occupation" rules="required:occupation,true" :validateOnInput="true"
                  v-model="occupation">
                  <Multiselect v-bind="field" rules="required:occupation,true" ref="occupation_multiselect"
                    mode="single" trackBy="title" label="title" valueProp="value" placeholder="Select occupation"
                    class="form-control" searchable="true" v-model="occupation" :options="occupations" />
                </Field>
                <ErrorMessage name="occupation" class="validation-msg" />
              </div>
              <div class="col-lg-4 mb-4 pt-2">
                <label>Profession</label>
                <Field name="profession" class="form-control" :validateOnInput="true" v-model="guarantor.profession"
                  type="text" autoComplete="off" placeholder="Profession" autocapitalize="false" />

              </div>
            </div>
            <h4 style="border-bottom: 1px solid #cccccc; line-height: 2">
              Permanent Address
            </h4>
            <div class="row">
              <div class="col-lg-4 mb-3 pt-2">
                <label> Address Type </label>
                <div class="">
                  <span class="mr-3">
                    <input class="" v-model="guarantor.home_address.has_owned" name="home_address_type" :checked="guarantor?.home_address?.has_owned == 0 ? true : false
                      " type="radio" value="0" id="Rented" style="width: 20px; height: auto" />
                    <label class="form-check-label" for="Rented">
                      Rented
                    </label>
                  </span>
                  <span>
                    <input class="" v-model="guarantor.home_address.has_owned" name="home_address_type" :checked="guarantor.home_address.has_owned == 1 ? true : false
                      " type="radio" value="1" id="Owned" style="width: 20px; height: auto" />
                    <label class="form-check-label" for="Owned"> Owned </label>
                  </span>
                </div>
                <ErrorMessage name="home_address_type" class="validation-msg" />
              </div>

              <div class="col-lg-4 mb-3 pt-2">
                <label>
                  Address
                  <span class="text-danger">*</span></label>
                <Field name="home_address" id="home_address" rules="required:address" class="form-control"
                  v-model="guarantor.home_address.address" type="text" autoComplete="off"
                  v-bind:placeholder="'Address'" />
                <ErrorMessage name="home_address" class="validation-msg" />
              </div>
              <div class="col-lg-4 mb-3 pt-2">
                <label>
                  City
                  <span class="text-danger">*</span></label>
                <Field name="home_city" id="home_city" rules="required:city" class="form-control"
                  v-model="guarantor.home_address.city" type="text" autoComplete="off" v-bind:placeholder="'City'" />
                <ErrorMessage name="home_city" class="validation-msg" />
              </div>
              <div class="col-lg-4 mb-3 pt-2">
                <label>
                  State
                  <span class="text-danger">*</span>
                </label>
                <Field v-slot="{ field }" name="home_state" rules="required:state,true" :validateOnInput="true"
                  v-model="guarantor.home_address.state_id">
                  <Multiselect v-bind="field" rules="required:state,true" ref="home_state_multiselect" mode="single"
                    trackBy="name" label="name" valueProp="id" placeholder="Select State" class="form-control"
                    v-model="guarantor.home_address.state_id" searchable="true" :options="stateList" />
                </Field>
                <ErrorMessage name="home_state" class="validation-msg" />
              </div>
              <div class="col-lg-4 mb-3 pt-2">
                <label>
                  Pincode
                  <span class="text-danger">*</span></label>
                <Field name="home_pincode" id="home_pincode" rules="required:pincode|pincode" class="form-control"
                  v-model="guarantor.home_address.pincode" type="text" autoComplete="off"
                  v-bind:placeholder="'Pincode'" />
                <ErrorMessage name="home_pincode" class="validation-msg" />
              </div>
            </div>
            <h4 style="border-bottom: 1px solid #cccccc; line-height: 2">
              Residential Address
            </h4>
            <div class="form-check pb-3">
              <input class="form-check-input" type="checkbox" value="" @click="checkSameAsAbove" id="same_as_above" />
              <label class="form-check-label text-danger" for="same_as_above">
                Same as above
              </label>
            </div>
            <div class="row">
              <div class="col-lg-4 mb-3 pt-2">
                <label> Address Type </label>
                <div class="">
                  <span class="mr-3">
                    <input class="" v-model="guarantor.residential_address.has_owned" name="residential_address_type"
                      :checked="guarantor.residential_address.has_owned == 0
                        ? true
                        : false
                        " type="radio" value="0" id="Office_Rented" style="width: 20px; height: auto" />
                    <label class="form-check-label" for="Office_Rented">
                      Rented
                    </label>
                  </span>
                  <span>
                    <input class="" v-model="guarantor.residential_address.has_owned" name="residential_address_type"
                      :checked="guarantor.residential_address.has_owned == 1
                        ? true
                        : false
                        " type="radio" value="1" id="office_Owned" style="width: 20px; height: auto" />
                    <label class="form-check-label" for="office_Owned">
                      Owned
                    </label>
                  </span>
                </div>
                <ErrorMessage name="residential_address_type" class="validation-msg" />
              </div>
              <div class="col-lg-4 mb-3 pt-2">
                <label> Address </label>
                <Field name="residential_address" id="residential_address" class="form-control"
                  v-model="guarantor.residential_address.address" type="text" autoComplete="off"
                  v-bind:placeholder="'Address'" />
                <ErrorMessage name="residential_address" class="validation-msg" />
              </div>
              <div class="col-lg-4 mb-3 pt-2">
                <label> City </label>
                <Field name="office_city" id="office_city" class="form-control"
                  v-model="guarantor.residential_address.city" type="text" autoComplete="off"
                  v-bind:placeholder="'City'" />
                <ErrorMessage name="office_city" class="validation-msg" />
              </div>

              <div class="col-lg-4 mb-3 pt-2">
                <label> State </label>
                <Field v-slot="{ field }" name="office_state" :validateOnInput="true"
                  v-model="guarantor.residential_address.state_id">
                  <Multiselect v-bind="field" ref="office_state_multiselect" mode="single" trackBy="name" label="name"
                    valueProp="id" placeholder="Select State" class="form-control" searchable="true"
                    v-model="guarantor.residential_address.state_id" :options="stateList" />
                </Field>
                <ErrorMessage name="office_state" class="validation-msg" />
              </div>

              <div class="col-lg-4 mb-3 pt-2">
                <label> Pincode </label>
                <Field name="office_pincode" id="office_pincode" class="form-control"
                  v-model="guarantor.residential_address.pincode" rules="pincode" type="text" autoComplete="off"
                  v-bind:placeholder="'Pincode'" />
                <ErrorMessage name="office_pincode" class="validation-msg" />
              </div>
            </div>
            <h4 style="border-bottom: 1px solid #cccccc; line-height: 2" v-if="guarantor_uuid">
              Kyc Details
            </h4>

            <div class="row" v-if="guarantor_uuid">
              <div class="col-lg-12">
                <div class="card rounded-2" style="border-radius: 10px">
                  <div class="card-header pb-2 pt-1">
                    <div class="row">
                      <div class="col-lg-12 text-right">
                        <a href="javascript:void(0)" @click="onUplaodKycDocument">
                          + Upload</a>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="table-responsive">
                          <table class="table table-primary mg-b-0">
                            <thead>
                              <tr>
                                <th scope="col" v-if="
                                  kycLoanDocuments.length > 0 &&
                                  userType !== 'ENQUIRY_USER'
                                "></th>
                                <th scope="col">Document</th>
                                <th scope="col">Updated By</th>
                                <th scope="col">Updated At</th>
                                <th scope="col" v-show="userType !== 'ENQUIRY_USER'">
                                  Approved By
                                </th>
                                <th scope="col" v-show="userType !== 'ENQUIRY_USER'">
                                  Approved At
                                </th>
                                <th scope="col" class="">Action</th>
                              </tr>
                            </thead>
                            <tbody v-if="kycLoanDocuments.length > 0">
                              <tr v-for="(item, i) in kycLoanDocuments" :key="item.id">
                                <td class="" v-if="userType !== 'ENQUIRY_USER'">
                                  <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                                    class="'custom-control-input'" @change="openConfirmation($event, item)"
                                    :id="'create_' + i" v-model="item.has_checked" />
                                </td>
                                <td>{{ item?.title }}</td>
                                <td>
                                  {{
                                    item?.added_by?.name
                                      ? item?.added_by?.name
                                      : "-"
                                  }}
                                </td>
                                <td>
                                  <span>
                                    {{
                                      $helperService.getFormattedDate(
                                        item?.updated_at
                                      )
                                    }}</span>
                                </td>
                                <td v-show="userType !== 'ENQUIRY_USER'">
                                  {{
                                    item?.approved_by_user?.name
                                      ? item?.approved_by_user?.name
                                      : "-"
                                  }}
                                </td>
                                <td v-show="userType !== 'ENQUIRY_USER'">
                                  <span>
                                    {{
                                      $helperService.getFormattedDate(
                                        item?.approved_at
                                      )
                                    }}</span>
                                </td>

                                <td style="cursor: pointer">
                                  <img v-if="
                                    item.doc_img != null || item.doc_img == ''
                                  " :src="item.doc_img
                                    ? item.doc_img
                                    : '/static/img/placeholder.png'
                                    " class="img-rounded" style="width: 50px" alt=""
                                    v-on:click="openImage(item.doc_img, i)" />

                                  <a href="javascript:void(0)" @click="
                                    downloadPdf(item.doc_img, item.title)
                                    " class="mx-2">Download</a>
                                  <a href="javascript:void(0)" @click="editKycDocument(item, 'EDIT')"
                                    class="mx-2">Edit</a>
                                  <a title="Delete" class="mx-2" style="cursor: pointer"
                                    @click="onDeleteKycDocument(item)"><img src="/static/img/delete-icon.svg"
                                      alt="" /></a>
                                </td>
                              </tr>
                            </tbody>
                            <template v-else>
                              <tr>
                                <td :colspan="userType !== 'ENQUIRY_USER' ? 8 : 5">
                                  <div class="text-center m-5">
                                    <h3>Sorry,record not found.</h3>
                                  </div>
                                </td>
                              </tr>
                            </template>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h4 style="border-bottom: 1px solid #cccccc; line-height: 2">
              Upload Documents
            </h4>
            <div class="row">
              <div class="col-lg-4 col-sm-4 mt-3 text-center">
                <div class="card border p-3 justify-content-center">
                  <h5 class="text-bolder">Photo</h5>
                  <button @click="OpenPhotoModal('APPLICATION')" type="button" class="btn btn-brand-02">
                    <span v-if="guarantor.guarantor_photo">Re-Take</span>
                    <span v-else>Take Photo</span>
                  </button>
                  <img :src="guarantor.guarantor_photo" id="img_hoder_2" class="img-fluid mt-3 w-100"
                    v-if="guarantor.guarantor_photo" style="height: 120px; object-fit: contain" />
                </div>
              </div>
              <div class="col-lg-4 col-sm-4 mt-3 text-center">
                <div class="card border p-3 justify-content-center">
                  <h5 class="text-bolder">Signature</h5>
                  <button @click="OpenScanModal('APPLICATION')" type="button" class="btn btn-brand-02">
                    <span v-if="guarantor.guarantor_thumb_img">Re-Scan</span>
                    <span v-else>Click to Scan</span>
                  </button>
                  <img :src="guarantor.guarantor_thumb_img" id="img_hoder_1" class="img-fluid mt-3 w-100"
                    v-if="guarantor.guarantor_thumb_img" style="height: 120px; object-fit: contain" />
                </div>
              </div>
              <div class="col-lg-4 col-sm-4 mt-3 text-center">
                <div class="card border p-3 justify-content-center">
                  <h5 class="text-bolder">Video</h5>
                  <button href="javascript:void(0)" class="btn btn-brand-02" v-if="guarantor_video == null"
                    @click="OpenUploadGurantorVideo($event)">
                    Upload Video
                  </button>
                  <button href="javascript:void(0)" class="btn btn-brand-02" v-else
                    @click="OpenUploadGurantorVideo($event)">
                    Re-Upload Video
                  </button>
                  <div v-if="guarantor_video != null" class="text-center">
                    <video controls id="video-tag" style="width: 100% !important; height: 150px">
                      <source id="video-source" style="
                          width: 80% !important;
                          height: 153px;
                          border-radius: 10px;
                          object-fit: contain;
                        " :src="guarantor_video" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div class="mb-3">
                    <input class="d-none" type="file" id="uploadGurantorVideo" accept="video/*"
                      @change="onUploadGurantorVideo($event)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mb-3 pt-3">
            <button type="button" class="btn btn-brand-01 wb-save-btn mx-3" @click="$router.go(-1)">
              Cancel
            </button>
            <button type="submit" class="btn btn-brand-01 wb-save-btn" id="guarantor-save">
              Save
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <div class="modal fade wb-modal-wrapper" id="uploadKycDocs" tabindex="-1" role="dialog"
    aria-labelledby="uploadKycDocs" aria-hidden="true" style="pointer-events: none">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a class="close mr-3 mt-3 text-right" @click="onCloseUplaodKycModal()" aria-label="Close">
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Upload Document</span>
          </h5>
        </div>
        <Form @submit="onUploadkycDetails" class="columns column is-multiline is-12" ref="onUploadkycDetails">
          <div class="modal-body form-style pb-0 px-lg-5">
            <div class="signin-form">
              <div class="col-lg-12 mb-4">
                <label>Document Type
                  <span class="text-danger">*</span>
                </label>
                <Field v-slot="{ field }" name="document_type" rules="required:document type,true"
                  :validateOnInput="true" v-model="kycDocuments.doc_id">
                  <Multiselect v-bind="field" ref="document_type_multiselect" mode="single" trackBy="title"
                    label="title" valueProp="uuid" @select="afterValueChange()" placeholder="Select Document Type"
                    class="form-control" v-model="kycDocuments.doc_id" :options="kycDocumentList" :searchable="true"
                    :createOption="true" />
                </Field>
                <ErrorMessage name="document_type" class="validation-msg" />
              </div>

              <div class="col-lg-12 mb-4">
                <div class="form-group">
                  <label>
                    Document Number
                    <span class="text-danger" v-if="is_optional == 0">*</span>
                  </label>
                  <Field name="doc_number" v-model="kycDocuments.doc_no" class="form-control" :rules="is_optional == 0
                    ? documentTitle == 'AADHAAR_CARD'
                      ? 'required:document number,true|aadhaar'
                      : documentTitle == 'PAN_CARD'
                        ? 'required:document number,true|PAN'
                        : ''
                    : ''
                    " :validateOnInput="true" type="text" autoComplete="off" placeholder="Document number"
                    autocapitalize="false" />
                  <ErrorMessage name="doc_number" class="validation-msg" />
                </div>
              </div>

              <div class="col-lg-12 mb-4" v-if="has_additional_info == 1">
                <div class="form-group">
                  <label>Issue Date
                    <span class="text-danger">*</span>
                  </label>
                  <Field name="issue_date" class="form-control" v-slot="{ date }"
                    :rules="is_optional == 0 ? 'required:issue date,true' : ''" id="issue_date"
                    v-model="kycDocuments.issue_date">
                    <VueDatePicker v-bind="date" placeholder="Select Issue Date " style="height: 46px !important"
                      v-model="kycDocuments.issue_date" :type="boolean" :format="'dd/MM/yyyy'" :default="true"
                      :enable-time-picker="false">
                    </VueDatePicker>
                  </Field>
                  <ErrorMessage name="issue_date" class="validation-msg" />
                </div>
              </div>
              <div class="col-lg-12 mb-4" v-if="has_additional_info == 1">
                <div class="form-group">
                  <label>Expiry Date
                    <span class="text-danger">*</span>
                  </label>
                  <Field name="expired_date" class="form-control" v-slot="{ date }"
                    :rules="is_optional == 0 ? 'required:expiry date,true' : ''" id="expired_date"
                    v-model="kycDocuments.expiry_date">
                    <VueDatePicker v-bind="date" placeholder="Select Expired Date " style="height: 46px !important"
                      v-model="kycDocuments.expiry_date" :type="boolean" :format="'dd/MM/yyyy'" :default="true"
                      :enable-time-picker="false">
                    </VueDatePicker>
                  </Field>
                  <ErrorMessage name="expired_date" class="validation-msg" />
                </div>
              </div>
              <div class="col-lg-12 mb-4">
                <div class="form-group">
                  <label>Document
                    <!-- <span class="text-danger">*</span> -->
                  </label>
                  <div class="wb-browse-file" style="cursor: pointer" @click="selectKycFile($event)">
                    <Field name="upload_kyc_file" v-model="kycFileName" type="text" autoComplete="off"
                      placeholder="Browse File" class="form-control" :disabled="disabled == 1" />
                    <Field id="selectKycFile" name="upload_kyc_file"
                      accept=".doc, .docx,.ppt, .pptx,.txt,.pdf,.jpeg,.jpg,.png,.webp" style="display: none" type="file"
                      placeholder="Browse File" @change="onKycDocumentChange($event)" class="form-control" />
                    <a style="cursor: pointer" @click="selectKycFile($event)"><img src="/static/img/browse-icon.svg"
                        alt="" /></a>
                  </div>
                  <ErrorMessage name="upload_kyc_file" class="validation-msg" />
                </div>
                <a v-if="downloadKycDocument != null" style="cursor: pointer"
                  @click="downloadPdf(downloadKycDocument, 'kyc Document')"><img src="/static/img/download-icon.svg"
                    class="mx-3" alt="" />Download</a>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button id="cancel-btn" @click="onCloseUplaodKycModal()" type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2">
              Cancel
            </button>
            <button :disabled="hasSomeError" type="submit" class="btn btn-brand-01 wb-save-btn" id="loan-kyc-document">
              Save
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <div class="modal fade" id="photoModal" tabindex="-1" aria-labelledby="photoModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Photo</h5>
          <button type="button" class="close" @click="onCloseSavePhoto()" aria-label="Close">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-sm-12 text-center">
              <div class="row">
                <div class="col-lg-5">
                  <div class="mb-3">
                    <button id="opencamera" @click="openCam()" class="btn btn-brand-02 mb-3">
                      Open Camera
                    </button>
                    <button id="startbutton" @click="startupGuarantor()" class="btn btn-brand-02 mb-3">
                      Take Photo
                    </button>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="mt-3">
                    <h5 class="text-center">OR</h5>
                  </div>
                </div>
                <div class="col-lg-5">
                  <div class="mb-3">
                    <input class="d-none" type="file" id="TakeGaurantorPhoto" accept="image/*"
                      @change="onGaurantorChange($event)" />
                    <h6 class="text-primary text-center">
                      <a href="javascript:void(0)" @click="OpenGaurantorSelectImage($event)"
                        class="btn btn-brand-02 mb-3">Select Photo</a>
                    </h6>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-sm-12 text-center">
                  <h5 class="text-bolder">
                    <div class="camera">
                      <video class="d-none" id="video">
                        Video stream not available.
                      </video>
                    </div>
                  </h5>
                  <canvas id="canvas" class="d-none"> </canvas>
                  <h5 class="text-bolder">
                    <img id="img_hoder_3" v-if="showUploadImage" :src="guarantor_photo" class="img-fluid" width="100"
                      height="50" />
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button id="cancel-btn" type="button" @click="onRemoveApplicantPhoto()" v-if="guarantor_photo"
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2">
            Clear
          </button>
          <button id="cancel-btn" type="button" @click="onCloseSavePhoto()" v-else
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2">
            Cancel
          </button>
          <button type="button" class="btn btn-brand-01 wb-save-btn" v-if="guarantor_photo" id="image-upload-btn"
            @click="savePhoto()">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="thumbModal" tabindex="-1" aria-labelledby="thumbModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Signature Update</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-sm-12 text-center">
              <div class="row">
                <div class="col-lg-5">
                  <div class="mb-3">
                    <h5 class="text-bolder">
                      <a @click="addLoanApplication('APPLICATION')" :disabled="scanLoader" class="btn btn-brand-02">
                        <span v-if="guarantor_thumb_img">Re-Scan</span>
                        <span v-else>Click to Scan </span><span v-if="scanLoader" class="mx-2 spinner"></span>
                      </a>
                    </h5>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="mt-3">
                    <h5 class="text-center">OR</h5>
                  </div>
                </div>
                <div class="col-lg-5">
                  <div class="mb-3">
                    <input class="d-none" type="file" id="TakeThumbPhotos" accept="image/*"
                      @change="onTakeGaurantorThumb($event)" />
                    <h6 class="text-primary text-center">
                      <a href="javascript:void(0)" class="btn btn-brand-02"
                        @click="OpenTakeGaurantorSelectThumb($event)">Select Signature</a>
                    </h6>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 text-center">
                  <div class="mb-3">
                    <h5 class="text-bolder">
                      <img id="img_hoder_4" v-if="showSignatureView && guarantor_thumb_img" :src="guarantor_thumb_img"
                        class="img-fluid" width="100" height="50" />
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button id="cancel-btn" type="button" @click="this.guarantor_thumb_img = ''" v-if="guarantor_thumb_img"
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2">
            Remove
          </button>
          <button id="thumb-btn" type="button" data-dismiss="modal" v-else
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2">
            Cancel
          </button>
          <button type="button" class="btn btn-brand-01 wb-save-btn" v-if="guarantor_thumb_img" id="thumb-scan"
            @click="saveThumb()">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationModal @remove="onDeleteKycDocumentAPI" ref="deleteKycDocument" />
  <div class="modal fade wb-modal-wrapper" id="onVerify" tabindex="-1" role="dialog" aria-labelledby="onVerify"
    aria-hidden="true" style="pointer-events: none">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a class="close mr-3 mt-3 text-right" @click="onCloseVerify()" aria-label="Close">
          <img src="/static/img/close-icon.svg" width="20" />
        </a>

        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Confirmation</span>
          </h5>
        </div>

        <div class="modal-body form-style pb-0 px-lg-5">
          <h5>Are you sure you want to verify selected documents?</h5>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button id="cancel-btn" @click="onCloseVerify()" type="button"
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2">
            No
          </button>
          <button @click="onSetVerifyDocument()" class="btn btn-brand-01 wb-save-btn" id="verification-btn">
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade wb-modal-wrapper" id="onConfirmation" tabindex="-1" role="dialog" aria-labelledby="onVerify"
    aria-hidden="true" style="pointer-events: none">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a class="close mr-3 mt-3 text-right" @click="onCloseConfirmation()" aria-label="Close">
          <img src="/static/img/close-icon.svg" width="20" />
        </a>

        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Confirmation</span>
          </h5>
        </div>

        <div class="modal-body form-style pb-0 px-lg-5">
          <h5>
            Are you sure you want to
            {{ document_status === 0 ? "reject" : "verify" }} selected document?
          </h5>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button id="cancel-btn" @click="onCloseConfirmation()" type="button"
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2">
            No
          </button>
          <button @click="onSetLoanKycDocumentStatus()" class="btn btn-brand-01 wb-save-btn"
            id="document-verification-btn">
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>

  <vue-easy-lightbox :visible="visibleRef" :imgs="showableImage" :index="indexRef" @hide="onHide"></vue-easy-lightbox>
</template>
<style>
.multiselect-tags-search {
  top: -1px !important;
}
</style>
<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import VueEasyLightbox from "vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import "vue-easy-lightbox/external-css/vue-easy-lightbox.css";
import { ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "AddGuarantor",

  components: {
    Multiselect,
    Form,
    Field,
    ErrorMessage,
    VueDatePicker,
    VueEasyLightbox,
    ConfirmationModal,
  },
  data() {
    return {
      hasSomeError:false,
      has_search_api_call: false,
      id: this.$route.params.id,
      guarantor_uuid: this.$route.params.guarantor_uuid,
      guarantor: {
        name: "",
        email: "",
        gender: "MALE",
        date_of_birth: "",
        mobile_number: "",
        aadhaar_card_no: "",
        pan_card_no: "",
        home_address: {
          has_owned: "0",
          address: "",
          city: "",
          pincode: "",
          state_id: "",
          type: "HOME",
          address_category: "PERMANENT",
        },
        residential_address: {
          has_owned: "0",
          address: "",
          city: "",
          pincode: "",
          state_id: "",
          type: "RESIDENTIAL",
          address_category: "RESIDENTIAL",
        },
      },
      showableImage: "",
      visibleRef: ref(false),
      indexRef: ref(0),
      occupation: "",
      guarantor_photo: "",
      photo_text: "Open Camera",
      showGuarantorPhotot: false,
      guarantor_thumb_img: "",
      scanLoader: false,
      guarantor_scan_thumb_img: "",
      guarantor_video: null,
      Guarantorwidth: 320,
      Guarantorheight: 0,
      Guarantorstreaming: false,
      isWebcamOpen: false,
      GuarantorlocalStream: null,
      video: null,
      canvas: null,
      Guarantorphoto: null,
      startbutton: null,
      occupations: [],
      stateList: [],
      guarantor_id: null,
      comming_from: "",
      kycDocumentList: [],
      kycDocuments: {
        doc_id: "",
        doc_no: "",
        issue_date: "",
        expiry_date: "",
      },
      documentTitle: "",
      kycDocumentFile: "",
      kycFileName: "",
      has_additional_info: "",
      is_optional: "",
      kycLoanDocuments: [],
      downloadKycDocument: null,
      kycDocumentType: "ADD",
      kycDocummentUuid: "",
      requestedLoanAmont: "",
      flag: false,
      stream: null,
      userType: null,
      documentIds: [],
      isShowApproveBtn: false,
      document_id: null,
      document_status: 0,
      guarantor_mobile_number:""
    };
  },
  watch: {
    "guarantor.mobile_number": function (newVal, oldVal) {
      console.log("mobile number", newVal, oldVal);
      if (newVal === this.guarantor_mobile_number) {
        return;
      }
      if (newVal && newVal.length >= 10) {
        console.log("mobile number", newVal, oldVal);
        this.checkMobileNumber();
      } else {
        this.clearGuarantorPreFieldData();
      }
    }
  },
  mounted() {
    this.userType = localStorage.getItem("user_type");
    this.onGetOccupation();
    this.onGetStateList();
    if (this.guarantor_uuid) {
      this.getUserInfo();
      this.onGetKycDocument();
    }
    this.comming_from = localStorage.getItem("loan_application_active_tab");
    if (this.comming_from == "DOCUMENT_VERIFIED") {
      this.startbutton = document.getElementById("startbutton");
      if (this.startbutton) {
        this.startbutton.removeEventListener("click", {});
        this.startbutton.addEventListener(
          "click",
          (ev) => {
            this.takepictureGuarantor();
            ev.preventDefault();
          },
          false
        );
      }
    }
  },
  methods: {
    selectAll(e) {
      this.documentIds = [];
      this.select_all = 0;
      this.kycLoanDocuments.forEach((doc) => {
        doc.is_checked = e.target.checked ? 1 : 0;
        if (e.target.checked) {
          this.isShowApproveBtn = e.target.checked;
          this.select_all = 1;
          this.documentIds.push(doc.id);
        }
        this.isShowApproveBtn = e.target.checked;
      });
      console.log(this.documentIds);
    },
    checkPermission(e, obj) {
      if (e.target.checked) {
        if (!this.documentIds.includes(obj.id)) {
          this.documentIds.push(obj.id);
        }
        obj.is_checked = 1;
        this.isShowApproveBtn = true;
      } else {
        obj.is_checked = 0;
        this.documentIds = this.documentIds.filter((id) => id !== obj.id);
        this.isShowApproveBtn = this.documentIds.length > 0;
      }
      let hasChecked = this.kycLoanDocuments.every(
        (doc) => doc.is_checked === 1
      );
      if (hasChecked) {
        this.select_all = 1;
      } else {
        this.select_all = 0;
      }
      console.log(this.documentIds);
    },
    onVerify() {
      if (this.documentIds.length > 0) {
        window.$("#onVerify").modal("show");
      } else {
        this.$toast.error("Please select atleast one document", {
          position: "top-right",
        });
      }
    },
    onCloseVerify() {
      window.$("#onVerify").modal("hide");
    },
    onSetVerifyDocument() {
      if (this.documentIds.length == 0) {
        this.$toast.error("Please select at least one document", {
          position: "top-right",
        });
        return;
      }

      var method = "POST";
      var action = "loans/assign/verification-officer";
      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: this.documentIds,
          _buttonId: "verification-btn",
          _hide_loader: true,
        })
        .then((res) => {
          this.onCloseVerify();
          this.$toast.success(res.message, { position: "top-right" });
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onDeleteKycDocument(item) {
      this.delete_id = item.id;
      this.$refs.deleteKycDocument.showModal(
        "Confirmation",
        "Are you sure you want to delete?",
        item
      );
    },
    onDeleteKycDocumentAPI() {
      var method = "DELETE";
      this.$api
        .webRequest({
          _method: method,
          _action:
            "loan/" +
            this.id +
            "/guarantor/" +
            this.guarantor_uuid +
            "/kyc-document/" +
            this.delete_id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteKycDocument.closeModal();
            this.getUserInfo();
            this.$toast.success(res.message, {
              position: "top-right",
            });
          }
        })
        .catch((e) => {
          this.$refs.deleteKycDocument.removeLoader();
          this.$toast.error(e.message, {
            position: "top-right",
          });
        });
    },
    onClearForm(formRefName) {
      this.$refs[formRefName].resetForm();
    },
    getUserInfo() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "loan/" + this.id + "/guarantor/" + this.guarantor_uuid,
        })
        .then((res) => {
          console.log("guarantor dataset :", res);
          // this.guarantor = res;
          this.guarantor_mobile_number = res?.mobile_number ;
          this.guarantor.guarantor_name = res?.guarantor_name;
          this.guarantor.email = res?.email != null ? res?.email : "";
          this.guarantor.gender = res?.gender != null ? res?.gender : "";
          this.guarantor.date_of_birth =
            res?.date_of_birth != null ? res?.date_of_birth : "";
          this.guarantor.mobile_number =
            res?.mobile_number != null ? res?.mobile_number : "";

            this.guarantor.alt_mobile_number_1 = res?.mobile_number != null ? res?.alt_mobile_number_1 : "";
            this.guarantor.alt_mobile_number_2 = res?.mobile_number != null ? res?.alt_mobile_number_2 : "";
       
          this.guarantor.aadhaar_card_no =
            res?.aadhaar_card_no != null ? res?.aadhaar_card_no : "";
          this.guarantor.pan_card_no =
            res?.pan_card_no != null ? res?.pan_card_no : "";
          if (res?.home_address != null) {
            this.guarantor.home_address = res?.home_address;
          }
          if (res?.residential_address != null) {
            this.guarantor.residential_address = res?.residential_address;
          }
          this.kycLoanDocuments = res?.kyc_documents;
          this.occupation = res?.occupation_obj?.value;
          this.guarantor_id = res.id;
          this.guarantor.guarantor_photo = res?.guarantor_photo;
          this.guarantor.guarantor_thumb_img = res?.guarantor_thumb_img;
          this.guarantor.guarantor_video = res?.guarantor_video;
          // this.guarantor_photo = res.guarantor_photo;
          this.guarantor_uuid = res?.uuid;
          this.guarantor_photo = res?.guarantor_photo;
          this.guarantor_thumb_img = res?.guarantor_thumb_img;
          if (res?.guarantor_video != null) {
            this.guarantor_video = res?.guarantor_video;
            window.$("#video-tag").click(function () {
              alert("The paragraph was clicked.");
            });
          }
          // if (res.guarantor_photo) {
          //   var canvas = document.getElementById("canvas");
          //   var ctx = canvas.getContext("2d");

          //   var img = new Image(); // Create new img element
          //   img.addEventListener(
          //     "load",
          //     function () {
          //       // Uncomment to hang in there
          //       canvas.height = 200;
          //       canvas.width = 250;
          //       ctx.drawImage(img, 0, 0);
          //       //  console.log("It Loaded !");
          //     },
          //     false
          //   );

          //   img.src = res.guarantor_photo;
          //   this.guarantor_photo = this.guarantor.guarantor_photo;
          // }

          // if (this.guarantor.guarantor_thumb_img) {
          //   this.guarantor_thumb_img = this.guarantor.guarantor_thumb_img;
          // }

          // if (res?.guarantor_video != null) {
          //   this.guarantor_video = res?.guarantor_video;
          //   window.$("#video-tag").click(function () {
          //     alert("The paragraph was clicked.");
          //   });
          // }
        })
        .catch(() => { });
    },
    clearphotoGuarantor() {
      const context = this.canvas.getContext("2d");
      context.fillStyle = "#AAA";
      context.fillRect(0, 0, this.canvas.width, this.canvas.height);
      this.guarantor_photo = "";

      // const data = this.canvas.toDataURL("image/png");
      // this.photo.setAttribute("src", data);
    },
    showViewLiveResultButton() {
      if (window.self !== window.top) {
        // Ensure that if our document is in a frame, we get the user
        // to first open it in its own tab or window. Otherwise, it
        // won't be able to request permission for camera access.
        document.querySelector(".contentarea").remove();
        const button = document.createElement("button");
        button.textContent = "View live result of the example code above";
        document.body.append(button);
        button.addEventListener("click", () => window.open(location.href));
        return true;
      }
      return false;
    },
    onUploadGurantorVideo(e) {
      const videoSrc = document.querySelector("#video-source");
      const videoTag = document.querySelector("#video-tag");
      let files = e.target.files;
      console.log("file type ", files);
      this.guarantor_video = files[0];
      console.log("the video file is :", this.guarantor_video);
      if (e.target.files && e.target.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          console.log(e.target.result, "after selecting file");
          videoSrc.src = e.target.result;
          videoTag.load();
        }.bind(this);
        reader.readAsDataURL(e.target.files[0]);
      }
    },
    OpenUploadGurantorVideo(e) {
      this.guarantor_video = "";
      e.stopImmediatePropagation();
      window.$("#uploadGurantorVideo").click();
    },
    onCloseSavePhoto() {
      this.guarantor_photo = "";
      window.$("#video").removeClass("d-block");
      window.$("#video").addClass("d-none");
      this.StopWebCam();
      window.$("#photoModal").modal("hide");
    },
    saveThumb() {
      if (this.guarantor_thumb_img) {
        this.guarantor.guarantor_thumb_img = this.guarantor_thumb_img;
      }
      window.$("#thumbModal").modal("hide");
      console.log(this.guarantor.guarantor_thumb_img);
      if (typeof this.guarantor_thumb_img === "object") {
        var reader = new FileReader();
        reader.onload = function (event) {
          var imageData = event.target.result;
          var imgElement = document.getElementById("img_hoder_1");
          imgElement.src = imageData;
        };
        reader.readAsDataURL(this.guarantor.guarantor_thumb_img);
      }
    },
    savePhoto() {
      if (this.guarantor_photo) {
        this.guarantor.guarantor_photo = this.guarantor_photo;
      }
      console.log(this.guarantor.guarantor_photo);
      window.$("#video").removeClass("d-block");
      window.$("#video").addClass("d-none");
      this.StopWebCam();
      window.$("#photoModal").modal("hide");
      if (typeof this.guarantor_photo === "object") {
        var reader = new FileReader();
        reader.onload = function (event) {
          var imageData = event.target.result;
          var imgElement = document.getElementById("img_hoder_2");
          imgElement.src = imageData;
        };
        reader.readAsDataURL(this.guarantor.guarantor_photo);
      }
    },
    StopWebCam() {
      if (this.localStream) {
        this.localStream.getTracks().forEach((track) => {
          track.stop();
        });
        this.localStream = null;
        this.isWebcamOpen = false;
      }
    },
    OpenScanModal(type) {
      this.thumb_type = type;
      if (type == "APPLICATION") {
        if (this.guarantor.guarantor_thumb_img) {
          this.guarantor_thumb_img = this.guarantor.guarantor_thumb_img;
        } else {
          this.guarantor_thumb_img = "";
        }
      }
      this.showSignatureView = true;
      window.$("#thumbModal").modal("show");
    },
    OpenPhotoModal(type) {
      this.photo_type = type;
      this.showUploadImageManually = false;
      window.$("#photoModal").modal("show");

      window.$("#opencamera").show();
      window.$("#startbutton").hide();
      this.guarantor_photo = "";
      if (type == "APPLICATION") {
        this.showUploadImage = false;
        if (this.guarantor.guarantor_photo) {
          this.guarantor_photo = this.guarantor.guarantor_photo;
          this.showUploadImage = true;
          if (this.showViewLiveResultButton()) {
            return;
          }
        } else {
          this.guarantor_photo = "";
          this.photo_text = "Open Camera";
        }
      }
    },
    openCam() {
      if (this.flag) {
        window.$("#canvas").hide();
      }
      window.$("#opencamera").hide();
      window.$("#startbutton").show();
      this.openCamera();
    },
    openCamera() {
      window.$("#canvas").hide();
      this.photo_text = "Take Photo";
      this.guarantor_photo = "";
      this.showViewLiveResultButton();
      if (window.$("#video").hasClass("d-none")) {
        window.$("#video").removeClass("d-none");
        window.$("#video").addClass("d-block");
      }
      this.startupGuarantor();
    },
    startupGuarantor() {
      if (this.showViewLiveResultButton()) {
        return;
      }
      this.showUploadImage = false;

      this.video = document.getElementById("video");
      this.canvas = document.getElementById("canvas");

      if (!this.isWebcamOpen) {
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: false })
          .then((stream) => {
            this.video.srcObject = stream;
            this.video.play();
            this.localStream = stream;
            this.isWebcamOpen = true;
            window.$("#video").attr("visibility", "hidden");
          })
          .catch((err) => {
            console.error(`An error occurred: ${err}`);
          });
      }

      this.video.addEventListener(
        "canplay",
        () => {
          if (!this.Guarantorstreaming) {
            this.Guarantorheight =
              this.video.videoHeight /
              (this.video.videoWidth / this.Guarantorwidth);
            // Firefox currently has a bug where the height can't be read from
            // the video, so we will make assumptions if this happens.

            if (isNaN(this.Guarantorheight)) {
              this.Guarantorheight = this.Guarantorwidth / (4 / 3);
            }

            // this.video.setAttribute("width", this.Guarantorwidth);
            this.video.setAttribute("width", "100%");
            this.video.setAttribute("height", this.Guarantorheight);
            this.canvas.setAttribute("width", this.Guarantorwidth);
            this.canvas.setAttribute("height", this.Guarantorheight);
            this.Guarantorstreaming = true;
          }
        },
        false
      );
      window.$("#video").show();

      this.clearphotoGuarantor();
    },
    checkSameAsAbove(e) {
      if (e.target.checked) {
        this.guarantor.residential_address.has_owned =
          this.guarantor.home_address.has_owned;
        this.guarantor.residential_address.state_id =
          this.guarantor.home_address.state_id;
        this.guarantor.residential_address.city =
          this.guarantor.home_address.city;
        this.guarantor.residential_address.address =
          this.guarantor.home_address.address;
        this.guarantor.residential_address.pincode =
          this.guarantor.home_address.pincode;
      } else {
        this.guarantor.residential_address.has_owned = 0;
        this.guarantor.residential_address.state_id = "";
        this.guarantor.residential_address.city = "";
        this.guarantor.residential_address.address = "";
        this.guarantor.residential_address.pincode = "";
      }

      console.log(
        "value from the checkSameAsAbove",
        this.guarantor.residential_address
      );
    },
    takepictureGuarantor() {
      const context = this.canvas.getContext("2d");
      if (this.Guarantorwidth && this.Guarantorheight) {
        this.canvas.width = this.Guarantorwidth;
        this.canvas.height = this.Guarantorheight;
        context.drawImage(
          this.video,
          0,
          0,
          this.Guarantorwidth,
          this.Guarantorheight
        );
        const data = this.canvas.toDataURL("image/png");
        this.guarantor_photo = data;
        this.flag = true;
        this.photo_text = "Open Camera";
        this.showUploadImage = true;
        window.$("#opencamera").show();
        window.$("#startbutton").hide();
        window.$("#canvas").show();
        window.$("#video").removeClass("d-block");
        window.$("#video").addClass("d-none");
      } else {
        this.clearphotoGuarantor();
      }
    },
    OpenGaurantorSelectImage(e) {
      this.guarantor_photo = "";
      e.stopImmediatePropagation();
      this.StopWebCam();
      window.$("#opencamera").show();
      window.$("#startbutton").hide();
      window.$("#video").removeClass("d-block");
      window.$("#video").addClass("d-none");
      window.$("#TakeGaurantorPhoto").click();
      this.showUploadImage = true;
    },
    onGaurantorChange(e) {
      let files = e.target.files;
      this.showUploadImageManually = true;
      this.showUploadImage = true;
      this.guarantor_photo = files[0];
      if (e.target.files && e.target.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          var canvas = document.getElementById("canvas");
          var ctx = canvas.getContext("2d");
          var img = new Image(); // Create new img element
          img.addEventListener(
            "load",
            function () {
              // Uncomment to hang in there
              canvas.height = 200;
              canvas.width = 200;
              ctx.drawImage(img, 0, 0);
              //  console.log("It Loaded !");
            },
            false
          );

          img.src = e.target.result;

          window.$("#img_hoder_3").attr("src", e.target.result);
          window.$("#img_hoder_2").attr("src", e.target.result);
          console.log("Image From The Selected file is:", files[0], canvas);
        };
      }
      reader.readAsDataURL(e.target.files[0]);
      console.log("Image From The Selected file is:", files[0]);
      console.log(
        "Image From The Selected file guarantor_photo is:",
        this.guarantor_photo
      );
    },
    onRemoveApplicantPhoto() {
      this.guarantor_photo = "";
      this.showUploadImage = false;
      this.showUploadImageManually = false;
    },
    addLoanApplication() {
      this.scanLoader = true;
      var obj = {
        Timeout: 10000,
      };
      this.$api
        .scan({
          _method: "POST",
          _body: obj,
        })
        .then((res) => {
          this.scanLoader = false;
          if (res && res.BMPBase64) {
            console.log(res);
            this.showSignatureView = true;
            this.guarantor_thumb_img = "data:image/bmp;base64," + res.BMPBase64;
            this.guarantor_scan_thumb_img = {
              image: "data:image/bmp;base64," + res.BMPBase64,
            };
          }
        })
        .catch(() => { });
    },
    OpenTakeGaurantorSelectThumb(e) {
      this.guarantor_thumb_img = "";
      e.stopImmediatePropagation();
      window.$("#TakeThumbPhotos").click();
    },
    onTakeGaurantorThumb(e) {
      let files = e.target.files;
      this.guarantor_thumb_img = files[0];
      this.guarantor_scan_thumb_img = {
        image: files[0],
      };
      if (e.target.files && e.target.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          window.$("#img_hoder_4").attr("src", e.target.result);
        };
      }
      reader.readAsDataURL(e.target.files[0]);
      console.log("Image From The Selected file is:", files[0]);
    },
    saveGuarantor() {
      if (!this.guarantor_photo && !this.guarantor.guarantor_photo) {
        this.$toast.error("Please select guarantor photo", {
          position: "top-right",
        });
        return false;
      }
      // if (!this.guarantor_thumb_img && !this.guarantor.guarantor_thumb_img) {
      //   this.$toast.error("Please scan guarantor thumb", {
      //     position: "top-right",
      //   });
      //   return false;
      // }
      this.guarantor.date_of_birth = moment(
        String(this.guarantor.date_of_birth)
      ).format("Y-MM-DD");

      // if(this.guarantor){
      //     console.log("the value of the gauarantor are is : ",this.guarantor);
      //     return true;
      // }

      this.guarantor.occupation = this.occupation;
      var method = "POST";
      var action = "loan/" + this.id + "/guarantor";
      if (this.guarantor_uuid) {
        method = "PUT";
        action = "loan/" + this.id + "/guarantor/" + this.guarantor_uuid;
      }
      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: this.guarantor,
          _buttonId: "guarantor-save",
        })
        .then((res) => {
          this.guarantor_id = res.id;

          if (typeof this.guarantor_photo === "object") {
            setTimeout(() => {
              this.uploadFileGuarantor(this.guarantor_photo, "PHOTO");
            }, 500);
          }
          if (typeof this.guarantor_scan_thumb_img === "object") {
            setTimeout(() => {
              this.uploadFileGuarantor(this.guarantor_thumb_img, "THUMB");
            }, 1000);
          }
          console.log("Value from the video", this.guarantor_video);
          if (typeof this.guarantor_video === "object") {
            setTimeout(() => {
              this.uploadFileGuarantor(this.guarantor_video, "VIDEO");
            }, 1000);
          }
          // if (method == "POST") {
          //   this.$router.push("/edit-guarantor/" + this.id + "/" + res?.id);
          // } else {
          // }
          this.$router.go(-1);
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    uploadFileGuarantor(file, type) {
      console.log("uploadFileGuarantor ==> ", type);
      var url = "";
      var key = "";
      if (type == "VIDEO") {
        url = "loan/" + this.id + "/guarantor/" + this.guarantor_id + "/video";
        key = "video";
      } else {
        url =
          "loan/" + this.id + "/guarantor/" + this.guarantor_id + "/document";
        key = "image";
      }
      if (file) {
        this.$api
          .uploadImageAPI({
            _action: url,
            _file: file,
            _key: key,
            _body: { type: type },
          })
          .then(() => { })
          .catch((e) => {
            this.$toast.error(e.message, { position: "top-right" });
          });
      }
    },
    onGetStateList() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "select/states",
          _body: null,
          _buttonId: "",
          _hide_loader: false,
        })
        .then((res) => {
          this.stateList = res.list;
        })
        .catch(() => { });
    },
    onGetOccupation() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "select/occupations",
          _body: null,
          _buttonId: "",
          _hide_loader: false,
        })
        .then((res) => {
          this.occupations = res.list;
        })
        .catch(() => { });
    },
    editKycDocument(item, type) {
      this.kycDocummentUuid = item?.uuid;
      this.kycDocumentType = type;
      this.kycDocuments = {
        doc_id: item?.doc?.uuid,
        doc_no: item.doc_no,
      };
      if (item?.issue_date != null) {
        this.kycDocuments.issue_date = item?.issue_date;
      }
      if (item?.expiry_date != null) {
        this.kycDocuments.expiry_date = item?.expiry_date;
      }

      this.kycDocumentList.forEach((obj) => {
        if (obj.uuid == item?.doc?.uuid) {
          this.documentTitle = obj.code;
        }
      });
      this.downloadKycDocument = item?.doc_img;
      this.kycDcoumentFile = "";
      console.log("edit kyc details : ", item);
      window.$("#uploadKycDocs").modal("show");
    },
    onUplaodKycDocument() {
      window.$("#uploadKycDocs").modal("show");
    },
    onCloseUplaodKycModal() {
      window.$("#uploadKycDocs").modal("hide");
      this.kycDocuments = {
        doc_id: "",
        doc_number: "",
        issue_date: "",
        expiry_date: "",
      };
      this.kycDocumentFile = "";
      this.kycFileName = null;
      this.downloadKycDocument = null;
      this.kycDocummentUuid = "";
    },
    afterValueChange() {
      if (this.kycDocumentList.length > 0) {
        this.kycDocumentList.forEach((obj) => {
          if (obj.uuid === this.kycDocuments.doc_id) {
            this.documentTitle = obj.code;
            this.has_additional_info = obj.has_additional_info;
            this.is_optional = obj.is_optional;
            console.log(
              "the value is ",
              obj.title,
              obj.has_additional_info,
              obj.is_optional
            );
            const exists = this.kycLoanDocuments.some(doc => doc.doc_id === obj?.id);
            if (exists) {
              this.hasSomeError = true;
              console.log("Document with the specified ID exists.");
              this.$toast.error(`${obj.title} is already uploaded.`, {
                position: "top-right",
              })
              return false
            }else{
              this.hasSomeError = false;
            }
          }
        });
      }
    },
    onGetKycDocument() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "kyc-documents",
        })
        .then((res) => {
          this.kycDocumentList = res.list;
        })
        .catch(() => { });
    },
    onUploadkycDetails() {
      if (this.kycDocuments.issue_date) {
        this.kycDocuments.issue_date = moment(
          String(this.kycDocuments.issue_date)
        ).format("Y-MM-DD");
      }
      if (this.kycDocuments.expiry_date) {
        this.kycDocuments.expiry_date = moment(
          String(this.kycDocuments.expiry_date)
        ).format("Y-MM-DD");
      }

      // if (this.kycDocuments) {
      //     console.log("the value of the kyc document is :", this.kycDocuments);
      //     return true;
      // }
      var url = "";
      if (this.kycDocumentType == "EDIT") {
        url =
          "loan/" +
          this.id +
          "/guarantor/" +
          this.guarantor_uuid +
          "/kyc-document/" +
          this.kycDocummentUuid;
      } else {
        url =
          "loan/" +
          this.id +
          "/guarantor/" +
          this.guarantor_uuid +
          "/kyc-document";
      }
      this.$api
        .webRequest({
          _method: "POST",
          _action: url,
          _body: this.kycDocuments,
          _buttonId: "loan-kyc-document",
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          if (typeof this.kycDcoumentFile == "object") {
            this.onUploadKyCFiles(this.kycDcoumentFile, res?.id);
          } else {
            this.onCloseUplaodKycModal();
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },

    onUploadKyCFiles(file, uuid) {
      this.$api
        .uploadImageAPI({
          _action: "loan/" + this.id + "/kyc-document/" + uuid + "/upload",
          _key: "file",
          _buttonId: "loan-kyc-document",
          _file: this.kycDcoumentFile,
        })
        .then(() => {
          // this.$toast.success(res.message, { position: "top-right" });
          this.onCloseUplaodKycModal();
          this.getUserInfo();
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    selectKycFile(e) {
      e.stopImmediatePropagation();
      window.$("#selectKycFile").click();
    },
    onKycDocumentChange(e) {
      let files = e.target.files;
      if (files.length > 0) {
        this.kycFileName = files[0].name;
        this.kycDcoumentFile = files[0];
        console.log("kyc file:", this.kycDcoumentFile);
      } else {
        this.kycFileName = null;
        this.kycDocumentFile = null;
      }
    },
    openImage(image, index) {
      this.showableImage = image;
      this.indexRef = index;
      this.visibleRef = true;
    },
    onHide() {
      this.visibleRef = false;
      this.showableImage = "";
      this.indexRef = "";
    },
    downloadPdf(url, filename) {
      console.log(url, filename);
      var anchorElement = document.createElement("a");
      anchorElement.href = url;
      anchorElement.download = filename;
      anchorElement.target = "_blank";
      document.body.appendChild(anchorElement);
      console.log(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
    },
    openConfirmation(e, obj) {
      window.$("#onConfirmation").modal("show");
      this.document_id = obj.uuid;
      this.document_status = e.target.checked ? 1 : 0;
    },
    onCloseConfirmation() {
      window.$("#onConfirmation").modal("hide");
      this.document_id = null;
      this.document_status = 0;
      this.getUserInfo();
    },
    onSetLoanKycDocumentStatus() {
      if (this.document_id == null) {
        return this.$toast.error("Please select atleast one document", {
          position: "top-right",
        });
      }
      var method = "POST";
      var action = `loan/${this.id}/document/${this.document_id}/approve`;
      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: { status: this.document_status },
          _buttonId: "document-verification-btn",
          _hide_loader: true,
        })
        .then((res) => {
          this.onCloseConfirmation();
          this.$toast.success(res.message, { position: "top-right" });
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    checkMobileNumber() {
      this.has_search_api_call = true;
      this.$api
        .webRequest({
          _method: "POST",
          _action: "search/mobile_number",
          _body: { mobile_number: this.guarantor.mobile_number },
          _hide_loader: true,
        })
        .then((res) => {
          this.has_search_api_call = false;
          console.log("the response is :", res);
          this.guarantor.guarantor_name = res?.info?.name;
          this.guarantor.email =
            res?.info?.email != null ? res?.info?.email : "";
          this.guarantor.gender =
            res?.info?.info?.gender != null ? res?.info?.info?.gender : null;
          this.guarantor.date_of_birth =
            res?.info?.info?.date_of_birth != null
              ? res?.info?.info?.date_of_birth
              : "";
          this.occupation =
            res?.info?.info?.occupation != null
              ? res?.info?.info?.occupation?.value
              : "";

          this.guarantor.home_address.address =
            res?.info?.info?.address != null ? res?.info?.info?.address : "";
          this.guarantor.home_address.city =
            res?.info?.info?.city != null ? res?.info?.info?.city : "";
          this.guarantor.home_address.state_id =
            res?.info?.info?.state != null ? res?.info?.info?.state_id : null;
          this.guarantor.home_address.pincode =
            res?.info?.info?.pincode != null ? res?.info?.info?.pincode : "";

          this.guarantor.residential_address.address =
            res?.info?.info?.address != null ? res?.info?.info?.address : "";
          this.guarantor.residential_address.city =
            res?.info?.info?.city != null ? res?.info?.info?.city : "";
          this.guarantor.residential_address.state_id =
            res?.info?.info?.state != null ? res?.info?.info?.state_id : null;
          this.guarantor.residential_address.pincode =
            res?.info?.info?.pincode != null ? res?.info?.info?.pincode : "";
        })
        .catch((e) => {
          this.clearGuarantorPreFieldData();
          this.$toast.error(e.message, { position: "top-right" });
        });

    },
    clearGuarantorPreFieldData() {
      this.has_search_api_call = false;
      this.guarantor.guarantor_name = "";
      this.guarantor.email = "";
      this.guarantor.gender = "";
      this.guarantor.date_of_birth = "";
      this.occupation = "";
      this.guarantor.home_address.address = "";
      this.guarantor.home_address.city = "";
      this.guarantor.home_address.state_id = null;
      this.guarantor.home_address.pincode = "";

      this.guarantor.residential_address.address = "";
      this.guarantor.residential_address.city = "";
      this.guarantor.residential_address.state_id = null;
      this.guarantor.residential_address.pincode = "";
    },
  },
};
</script>

<style>
.multiselect-tags-search {
  top: -1px !important;
}
</style>
